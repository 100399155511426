export class WabaTemplatesComponentTransformer {

  /**
   * @type WabaTemplate
   */
  value
  /**
   * @type {Object}
   */
  data

  /**
   *
   * @param {WabaTemplate} value
   */
  constructor(value, data = {}) {
    this.value = JSON.parse(JSON.stringify(value))
    if (data && Object.keys(data)?.length > 0) {
      this.data = data
    } else {
      this.data = {}
    }

  }

  /**
   *
   * @param {string[]} matches
   * @param {TemplateComponent} item
   * @param {Object} iteratedItem
   * @param {boolean} fillData  - whether to fill data in discovered params
   * @param {Object} newFields
   * @param {Object} newFieldsTypes
   */
  #matchAndFill(matches, item, iteratedItem = {}, fillData = false, newFields = null, newFieldsTypes = null) {
    if (matches)
      for (let match of matches) {
        const pos = match.replace(/[\{\}]/g, '')
        const posNumber = parseInt(pos)
        const stringInFields = item.type.toString().toLowerCase() + `_${posNumber}`
        if (fillData && this.data?.[stringInFields]) {
          const whereToInsert = 'text'
          iteratedItem[whereToInsert] = iteratedItem[whereToInsert].replace(match, this.data[stringInFields])
        }
        if (newFields) newFields[stringInFields] = ''
        if (newFieldsTypes) newFieldsTypes[stringInFields] = item.type
      }
  }

  /**
   *
   * @param {string} input
   * @returns {RegExpMatchArray}
   */
  #matchVariables(input) {
    return input&&input.match(/\{\{\d+}}/gm)
  }

  /**
   *
   * @returns {WabaTemplatesComponentTransformer}
   */
  fillVariablesWithData() {
    const newFields = {}
    const newFieldsTypes = {}
    if (this.value?.components) {
      for (let i = 0; i < this.value.components.length; i++) {
        const item = this.value.components[i]
        if (item.type === "HEADER" && item.format !== 'TEXT') {
          let newKey
          switch (item.format) {
            case "IMAGE":
              newKey = 'image_url'
              break
            case "DOCUMENT":
              newKey = 'document_url'
              break
            case "VIDEO":
              newKey = 'video_url'
              break
          }
          if (newKey) {
            newFields[newKey] = ''
            newFieldsTypes[newKey] = item.type
          }
        } else if (item.type === "BUTTONS" && Array.isArray(item.buttons)) {
          for (let j = 0; j < item.buttons.length; j++) {
            const buttonItem = item.buttons[j]
            let matches = this.#matchVariables(buttonItem.url)
            if (matches) {
              const stringInFields = `button_url_${j}`
              if (this.data?.[stringInFields]) {
                buttonItem.url = buttonItem.url.replace(matches[0], this.data?.[stringInFields])
              }
              newFields[stringInFields] = ''
              newFieldsTypes[stringInFields] = item.type
            }
          }
        } else if (item.type === "CAROUSEL") {
          let carouselNewFields = []
          let carouselNewFieldsTypes = []
          for (let l = 0; l < item.cards.length; l++) {
            let cardNewFields = {}
            let cardNewFieldsType = {}
            for (let m = 0; m < item.cards[l].components.length; m++) {
              const itemCard = item.cards[l].components[m]
              if (itemCard.type === "HEADER") {
                let newKey
                switch (itemCard.format) {
                  case "IMAGE":
                    newKey = 'image_url'
                    break
                  case "VIDEO":
                    newKey = 'video_url'
                    break
                }
                if (newKey) {
                  cardNewFields[newKey] = ''
                  cardNewFieldsType[newKey] = itemCard.type
                }
              } else if (itemCard.type === "BUTTONS") {
                for (let j = 0; j < itemCard.buttons.length; j++) {
                  const buttonItem = itemCard.buttons[j]
                  let matches = this.#matchVariables(buttonItem.url)
                  if (matches) {
                    const stringInFields = `button_url_${j}`
                    if (this.data?.carousel?.[l]?.[stringInFields]) {
                      buttonItem.url = buttonItem.url.replace(matches[0], this.data?.carousel?.[l]?.[stringInFields])
                    }
                    cardNewFields[stringInFields] = ''
                    cardNewFieldsType[stringInFields] = itemCard.type
                  }
                }
              } else {
                let matches = this.#matchVariables(itemCard.text)
                if (matches) {
                  for (let match of matches) {
                    const pos = match.replace(/[\{\}]/g, '')
                    const posNumber = parseInt(pos)
                    const stringInFields = itemCard.type.toString().toLowerCase() + `_${posNumber}`
                    if (this.data?.carousel?.[l]?.[stringInFields]) {
                      itemCard.text = itemCard.text.replace(match, this.data?.carousel?.[l]?.[stringInFields])
                    }
                    cardNewFields[stringInFields] = ''
                    cardNewFieldsType[stringInFields] = itemCard.type
                  }
                }
              }
            }
            carouselNewFields.push(cardNewFields)
            carouselNewFieldsTypes.push(cardNewFieldsType)
          }
          newFields.carousel = carouselNewFields
          newFieldsTypes.carousel = carouselNewFieldsTypes
        } else {
          let matches = this.#matchVariables(item.text)
          this.#matchAndFill(matches, item, item, true, newFields, newFieldsTypes)
        }
      }
    }
    return this
  }

  getComponentsVariablesMeta() {
    const newFields = {}
    const newFieldsTypes = {}
    this.processMarkdown()
    if (this.value?.components) {
      for (let i = 0; i < this.value.components.length; i++) {
        const item = this.value.components[i]
        if (item.type === "HEADER" && item.format !== 'TEXT') {
          let newKey
          switch (item.format) {
            case "IMAGE":
              newKey = 'image_url'
              break
            case "DOCUMENT":
              newKey = 'document_url'
              break
            case "VIDEO":
              newKey = 'video_url'
              break
          }
          if (newKey) {
            newFields[newKey] = ''
            newFieldsTypes[newKey] = item.type
          }
        } else if (item.type === "BUTTONS" && Array.isArray(item.buttons)) {
          for (let j = 0; j < item.buttons.length; j++) {
            const buttonItem = item.buttons[j]
            let matches = this.#matchVariables(buttonItem.url)
            if (matches) {
              const stringInFields = `button_url_${j}`
              newFields[stringInFields] = ''
              newFieldsTypes[stringInFields] = item.type
            }
          }
        } else if (item.type === "CAROUSEL") {
          let carouselNewFields = []
          let carouselNewFieldsTypes = []
          for (let l = 0; l < item.cards.length; l++) {
            let cardNewFields = {}
            let cardNewFieldsType = {}
            for (let m = 0; m < item.cards[l].components.length; m++) {
              const itemCard = item.cards[l].components[m]
              if (itemCard.type === "HEADER") {
                let newKey
                switch (itemCard.format) {
                  case "IMAGE":
                    newKey = 'image_url'
                    break
                  case "VIDEO":
                    newKey = 'video_url'
                    break
                }
                if (newKey) {
                  cardNewFields[newKey] = ''
                  cardNewFieldsType[newKey] = itemCard.type
                }
              } else if (itemCard.type === "BUTTONS") {
                for (let j = 0; j < itemCard.buttons.length; j++) {
                  const buttonItem = itemCard.buttons[j]
                  let matches = this.#matchVariables(buttonItem.url)
                  if (matches) {
                    const stringInFields = `button_url_${j}`
                    cardNewFields[stringInFields] = ''
                    cardNewFieldsType[stringInFields] = itemCard.type
                  }
                }
              } else {
                let matches = this.#matchVariables(itemCard.text)
                if (matches) {
                  for (let match of matches) {
                    const pos = match.replace(/[\{\}]/g, '')
                    const posNumber = parseInt(pos)
                    const stringInFields = itemCard.type.toString().toLowerCase() + `_${posNumber}`
                    if (this.data?.carousel?.[l]?.[stringInFields]) {
                      itemCard.text = itemCard.text.replace(match, this.data?.carousel?.[l]?.[stringInFields])
                    }
                    cardNewFields[stringInFields] = ''
                    cardNewFieldsType[stringInFields] = itemCard.type
                  }
                }
              }
            }
            carouselNewFields.push(cardNewFields)
            carouselNewFieldsTypes.push(cardNewFieldsType)
          }
          newFields.carousel = carouselNewFields
          newFieldsTypes.carousel = carouselNewFieldsTypes
        } else {
          let matches = this.#matchVariables(item.text)
          this.#matchAndFill(matches, item, item, true, newFields, newFieldsTypes)
        }
      }
    }
    return {newFields, newFieldsTypes}
  }

  /**
   * @returns {WabaTemplate}
   */
  getResult() {
    return this.value
  }

  /**
   *
   * @returns {WabaTemplatesComponentTransformer}
   */
  orderComponents() {
    let order = {
      "HEADER": 1,
      "BODY": 2,
      "FOOTER": 3,
      "BUTTONS": 4,
    }
    Array.isArray(this.value.components) && this.value.components.sort((a, b) => order[a.type] - order[b.type])
    return this
  }

  /**
   *
   * @returns {WabaTemplatesComponentTransformer}
   */
  processMarkdown() {
    if(this.value?.category === 'AUTHENTICATION') {
      for (let j = 0; j < this.value.components.length; j++) {
        const item = this.value.components[j]
        if (item.type === 'BODY') {
          let text = "{{1}} is your verification code."
          if(item.add_security_recommendation) {
            text += "For your security, do not share this code."
          }
          item.text = text
        }
        if (item.type === 'FOOTER') {
          if(item.code_expiration_minutes) {
            item.text = `This code expires in ${item.code_expiration_minutes} minutes`
          }
        }
        if (item.type === 'BUTTONS') {
          item.buttons = item.buttons.map(btn => {
            if (btn?.otp_type === "COPY_CODE") {
              return {...btn, url: "{{1}}"}
            }
            return btn
          })
        }
      }
    } else if (this.value?.components) {
      for (let j = 0; j < this.value.components.length; j++) {
        const item = this.value.components[j]
        if (item.type === 'HEADER') {
          let text = (item.format && item.format === "MEDIA") ? "media_url" : item.text
          for (let i in item.examples) {
            if (!item.examples[i] || !item.examples[i].name) continue
            let example = item.examples[i]
            text = text.replaceAll(example.name, (example.value || example.name))
          }
          if (text) {
            item.text = text
          }
        } else if (item.type === 'BODY') {
          let tmp = item.text
          for (let i in item.examples) {
            if (!item.examples[i] || !item.examples[i].name) continue
            let example = item.examples[i]
            tmp = tmp.replaceAll(example.name, (example.value || example.name))
          }
          tmp = tmp.replace(/\*\*(.*?)\*\*/g, function (match, contents) {
            return `<strong>${contents}</strong>`;
          });
          tmp = tmp.replace(/_(.*?)_/g, function (match, contents) {
            return `<em>${contents}</em>`;
          });
          tmp = tmp.replace(/\*(.*?)\*/g, function (match, contents) {
            return `<em>${contents}</em>`;
          });
          tmp = tmp.replace(/~(.*?)~/g, function (match, contents) {
            return `<del>${contents}</del>`;
          });
          /* tmp = tmp.replace(/\{\{\d\}\}/gm, function (match, offset, contents, input_string) {
            match = match.replace(/\{\{/g, '\\{\\{')
            match = match.replace(/\}\}/g, '\\}\\}')
            return match
          })*/
          tmp = tmp.replace(/(?:\r\n|\r|\n)/gm, '<br>')
          item.text = tmp
        } else if (item.type === 'BUTTONS') {

        } else if (item.type === 'CAROUSEL') {
          for (let l = 0; l < item.cards.length; l++) {
            for (let m = 0; m < item.cards[l].components.length; m++) {
              const itemCard = item.cards[l].components[m]
              if (itemCard.type === 'HEADER') {

              } else if (itemCard.type === 'BODY') {
                let tmp = itemCard.text
                for (let i in itemCard.examples) {
                  if (!itemCard.examples[i] || !itemCard.examples[i].name) continue
                  let example = itemCard.examples[i]
                  tmp = tmp.replaceAll(example.name, (example.value || example.name))
                }
                tmp = tmp.replace(/\*\*(.*?)\*\*/g, function (match, contents) {
                  return `<strong>${contents}</strong>`;
                });
                tmp = tmp.replace(/_(.*?)_/g, function (match, contents) {
                  return `<em>${contents}</em>`;
                });
                tmp = tmp.replace(/\*(.*?)\*/g, function (match, contents) {
                  return `<em>${contents}</em>`;
                });
                tmp = tmp.replace(/~(.*?)~/g, function (match, contents) {
                  return `<del>${contents}</del>`;
                });
                tmp = tmp.replace(/(?:\r\n|\r|\n)/gm, '<br>')
                itemCard.text = tmp
              } else if (item.type === 'BUTTONS') {

              }
            }
          }
        }
      }
    }
    return this
  }
}
