<script>
  import {appClient} from '../../../../../helpers/store';
  import {_} from "svelte-i18n";
  import {onDestroy} from 'svelte';

  export let isOpen;
  export let activeOperator;
  export let activeOperators;
  export let operatorIdForFilter;
  export let activeTab;

  let listOperators = [];

  $:isOpen && setTimeout(scrollToEl, 1);

  function scrollToEl() {
    const container = document.querySelector('.OperatorFilter .scrollbar');
    const el = document.getElementById(`OperatorFilter-${operatorIdForFilter}`);
    if (el) {
      container.scrollTop = el.offsetTop - container.offsetTop;
    }
  }

  const unsub = activeOperators.subscribe(value => {
    if (value.length && activeOperator) {
      listOperators = value.filter(({profile}) => activeOperator?.profile?.id !== profile.id);
      operatorIdForFilter = 0;
    }
  });

  onDestroy(() => {
    unsub();
  });

  function select(id) {
    operatorIdForFilter = id;
    isOpen = false;
  }
</script>

<button type="button" id="OperatorFilter-{activeOperator.profile.id}"
  class="dropdown-item text-truncate"
  class:active={operatorIdForFilter === activeOperator.profile.id}
  disabled={operatorIdForFilter === activeOperator.profile.id}
  on:click={() => {select(appClient.currentUserOnetouch?.id); activeTab = 1}}>
  {$_('chat_inbox.Tab.my_open')}
</button>
<button type="button" id="OperatorFilter-{0}"
  class="dropdown-item text-truncate"
  class:active={operatorIdForFilter === 0}
  disabled={operatorIdForFilter === 0}
  on:click={() => {select(0); activeTab = 1}}>
  {$_('chat_inbox.Tab.all_open')}
</button>
{#each listOperators as item}
  <button type="button" id="OperatorFilter-{item.profile.id}"
    class="dropdown-item text-truncate"
    class:active={operatorIdForFilter === item.profile.id}
    disabled={operatorIdForFilter === item.profile.id}
    on:click={() => {select(item.profile.id); activeTab = 1}}>
    {item.profile.user.username}
  </button>
{/each}


<style>
  .dropdown-item {
    color: #495057;
  }

  .dropdown-item.active {
    background-color: #f8f9fa !important;
  }
</style>
