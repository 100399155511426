<script>
  import {Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Button} from "sveltestrap";
  import {onMount, onDestroy} from "svelte";
  import {_, date} from "svelte-i18n";
  import {fade} from "svelte/transition";
  import {defaultConst} from "../../common/constants";

  import Spinner from "../Spinners/SpinnerComponent.svelte";
  import MessagePreview from "../MessagePreview.svelte";


  export let toggle;
  export let show;
  export let template;
  export let channelAdditionalInfo;
  export let channel;

  let loading = false;
</script>


<div class="show-template-modal">
  <Modal
      centered
      class="show-template"
      fade={true}
      isOpen={show}
      scrollable
      size="md"
  >
    <ModalHeader toggle={toggle}>
      <span class="">{template.name}</span>
    </ModalHeader>
    {#if loading}
      <ModalBody>
        <Spinner/>
      </ModalBody>
    {:else}
      <ModalBody>
        <div class="container-fluid">
          <div id="chat-app_example" class="">
            <div class="d-flex justify-content-center">
              <MessagePreview {template} {channelAdditionalInfo} {channel} />
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter class="mx-0">
        <div class="container-fluid">
          <div class="row">
            <div class="col-7 p-0 d-flex justify-content-end">
              <button class="btn btn-outline-solitude-grey mr-3" on:click={toggle}>{$_('ok')}</button>
            </div>
          </div>
        </div>
      </ModalFooter>
    {/if}
  </Modal>
</div>
