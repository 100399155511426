<script>
  import {Modal, ModalBody, Row, Col, Button, Label} from "sveltestrap";
  import {_} from "svelte-i18n";
  import Spinner from "../Spinners/SpinnerComponent.svelte";
  import {Navigation} from 'swiper';
  import {Swiper, SwiperSlide} from 'swiper/svelte';

  export let show;
  export let template;
  export let channelAdditionalInfo;
  export let onClose;

  let newTemplate;
  let ready = false;
  let navigation ={
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  };

  function onChange(prop, i) {
    if (prop === 'header') {
      let text = (newTemplate[prop].format && newTemplate[prop].format === "MEDIA") ? "media_url" : newTemplate[prop].text;
      for (let i in newTemplate[prop].examples) {
        if (!newTemplate[prop].examples[i] || !newTemplate[prop].examples[i].name) continue;
        let example = newTemplate[prop].examples[i];
        text = text.replaceAll(example.name, (example.value || example.name));
      }
      if (text) {
        newTemplate[prop].sampleText = text;
      }
    } else if (prop === 'body') {
      let tmp = newTemplate[prop].text;
      for (let i in newTemplate[prop].examples) {
        if (!newTemplate[prop].examples[i] || !newTemplate[prop].examples[i].name) continue;
        let example = newTemplate[prop].examples[i];
        tmp = tmp.replaceAll(example.name, (example.value || example.name));
      }
      tmp = tmp.replace(/\*\*(.*?)\*\*/g, function (match, contents) {
        return `<strong>${contents}</strong>`;
      });
      tmp = tmp.replace(/_(.*?)_/g, function (match, contents) {
        return `<em>${contents}</em>`;
      });
      tmp = tmp.replace(/\*(.*?)\*/g, function (match, contents) {
        return `<em>${contents}</em>`;
      });
      tmp = tmp.replace(/~(.*?)~/g, function (match, contents) {
        return `<del>${contents}</del>`;
      });
      /* tmp = tmp.replace(/\{\{\d\}\}/gm, function (match, offset, contents, input_string) {
        match = match.replace(/\{\{/g, '\\{\\{')
        match = match.replace(/\}\}/g, '\\}\\}')
        return match;
      });*/
      tmp = tmp.replace(/(?:\r\n|\r|\n)/gm, '<br>');
      newTemplate[prop].sampleText = tmp;
    } else if (prop === 'buttons') {
    } else if (prop === 'carouselBody') {
      let tmp = newTemplate.carousel.cards[i].body.text;
      for (let index in newTemplate.carousel.cards[i].body.examples) {
        if (!newTemplate.carousel.cards[i].body.examples[index] || !newTemplate.carousel.cards[i].body.examples[index].name) continue;
        let example = newTemplate.carousel.cards[i].body.examples[index];
        tmp = tmp.replaceAll(example.name, (example.value || example.name));
      }
      tmp = tmp.replace(/\*\*(.*?)\*\*/g, function (match, contents) {
        return `<strong>${contents}</strong>`;
      });
      tmp = tmp.replace(/_(.*?)_/g, function (match, contents) {
        return `<em>${contents}</em>`;
      });
      tmp = tmp.replace(/\*(.*?)\*/g, function (match, contents) {
        return `<em>${contents}</em>`;
      });
      tmp = tmp.replace(/~(.*?)~/g, function (match, contents) {
        return `<del>${contents}</del>`;
      });
      tmp = tmp.replace(/(?:\r\n|\r|\n)/gm, '<br>');
      newTemplate.carousel.cards[i].body.sampleText = tmp;
    }
  }

  function onShow() {
    ready = false;
    newTemplate = Object.assign({}, template);
    let props = ['header', 'body'];
    for (let prop of props) {
      if (newTemplate[prop] && newTemplate[prop].format === 'MEDIA') {
        let tmp = {name: 'media_url'}
        if (newTemplate[prop].examples && newTemplate[prop].examples[0] && newTemplate[prop].examples[0].value) {
          tmp.value = newTemplate[prop].examples[0].value;
        }
        newTemplate[prop].examples = [tmp]
      } else {
        if (!newTemplate[prop] || !newTemplate[prop].text) continue;
        newTemplate[prop].sampleText = newTemplate[prop].text;

        let matches = newTemplate[prop].text.match(/\{\{\d+}}/gm);
        if (!newTemplate[prop].examples) newTemplate[prop].examples = [];
        if (matches) {
          let examples = [];
          for (let match of matches) {
            let pos = match.replace(/[\{\}]/g, '');
            pos--;
            examples[pos] = {name: match};
            if (newTemplate[prop].examples[pos] && newTemplate[prop].examples[pos].value) examples[pos].value = newTemplate[prop].examples[pos].value;
          }
          newTemplate[prop].examples = examples;
        } else {
          newTemplate[prop].examples = [];
        }
      }
    }

    if (newTemplate.buttons.length) {
      for (let btn of newTemplate.buttons) {
        if (!btn.url) continue;
        let matches = btn.url.match(/\{\{\d+}}/gm);
        if (!btn.examples) btn.examples = [];
        if (matches) {
          btn.examples = [{name: "full url", value: btn.examples?.[0]?.value}];
        } else {
          btn.examples = [];
        }
      }
    }

    if (newTemplate.carousel) {
      for (let card of newTemplate.carousel.cards) {
        card.body.sampleText = card.body.text;
        let matches = card.body.text.match(/\{\{\d+}}/gm);
        if (!card.body.examples) card.body.examples = [];
        if (matches) {
          let examples = [];
          for (let match of matches) {
            let pos = match.replace(/[\{\}]/g, '');
            pos--;
            examples[pos] = {name: match};
            if (card.body.examples[pos] && card.body.examples[pos].value) examples[pos].value = card.body.examples[pos].value;
          }
          card.body.examples = examples;
        } else {
          card.body.examples = [];
        }

        if (card.buttons.length) {
          for (let btn of card.buttons) {
            if (!btn.url) continue;
            let matches = btn.url.match(/\{\{\d+}}/gm);
            if (!btn.examples) btn.examples = [];
            if (matches) {
              btn.examples = [{name: "full url", value: btn.examples?.[0]?.value}];
            } else {
              btn.examples = [];
            }
          }
        }
      }
    }

    // console.dir(newTemplate, {depth: 7})
    if (newTemplate.header && newTemplate.header.format) onChange('header');
    if (newTemplate.body.text) onChange('body');
    if (newTemplate.carousel) onChange('carouselBody', 0);
    ready = true;
  }

  function save() {
    show = false;
    newTemplate = false;
    return onClose(newTemplate);
  }

  $: if (show) {
    onShow();
  };
</script>

{#if show}
  <Modal isOpen={show} fade={true} toggle={onClose} size="lg">
    <ModalBody>
      <h4 class="font-size-18 fw-600 mb-3">
        {$_("waba.template.add")}
        {#if (newTemplate.name)}&nbsp;"{newTemplate.name}"{/if}
      </h4>
      {#if ready}
        <Row>
          <Col md="6" xs="12" sm="12">
            <form name="examples" layout-padding layout="column" flex="50">
              {#if newTemplate.header?.format !== 'NONE' && newTemplate.header?.examples?.length}
                <div class="mb-4">
                  <Label class="fw-600">Header</Label>
                  {#if newTemplate.header.format === 'TEXT'}
                    {#each newTemplate.header.examples as example, index}
                      <input placeholder={example.name} class="form-control mb-2"
                        on:change={() => onChange('header')} name="header_example_{index}"
                        bind:value={example.value} type="text" required/>
                    {/each}
                  {/if}
                  {#if newTemplate.header.format === 'MEDIA'}
                    {#each newTemplate.header.examples as example, index}
                      <input placeholder={example.name} class="form-control mb-2"
                        on:change={() => onChange('header')} name="header_example_{index}"
                        bind:value={example.value} type="text" required/>
                    {/each}
                  {/if}
                </div>
              {/if}
              {#if newTemplate.body?.text && newTemplate.body?.examples?.length}
                <div class="mb-4">
                  <Label class="fw-600">Body</Label>
                  {#each newTemplate.body.examples as example, index}
                    <input placeholder={example.name} class="form-control mb-2"
                      on:change={() => onChange('body')} name="body_example_{index}"
                      bind:value={example.value} type="text" required/>
                  {/each}
                </div>
              {/if}
              {#if newTemplate.buttons?.length}
                <div>
                  {#if newTemplate.buttons.find(btn => btn.examples?.length)}
                    <Label class="fw-600">Button</Label>
                  {/if}
                  {#each newTemplate.buttons as btn}
                    {#if btn.examples?.length}
                      <input placeholder={btn.examples[0].name} class="form-control mb-2"
                        type="text" on:change={() => onChange('buttons')}
                        bind:value={btn.examples[0].value} required/>
                    {/if}
                  {/each}
                </div>
              {/if}
              {#if newTemplate?.carousel?.cards?.length}
                {#each newTemplate?.carousel.cards as card, i}
                  <div class="mb-4">
                    <Label class="fw-600">Card {i + 1}</Label>
                    <br/>
                    <Label class="fw-600">Header</Label>
                    {#each card.header.examples as example, index}
                      <input placeholder={example.name} class="form-control mb-2" name="header_example_{index}"
                        bind:value={example.value} type="text" required/>
                    {/each}
                    {#if card.body?.examples?.length}
                      <Label class="fw-600">Body</Label>
                      {#each card.body.examples as example, index}
                        <input placeholder={example.name} class="form-control mb-2"
                          on:change={() => onChange('carouselBody', i)} name="body_example_{index}"
                          bind:value={example.value} type="text" required/>
                      {/each}
                    {/if}
                    {#if card.buttons?.length}
                      {#if card.buttons.find(btn => btn.examples?.length)}
                        <Label class="fw-600">Button</Label>
                      {/if}
                      {#each card.buttons as btn}
                        {#if btn.examples?.length}
                          <input placeholder={btn.examples[0].name} class="form-control mb-2"
                            type="text" bind:value={btn.examples[0].value} required/>
                        {/if}
                      {/each}
                    {/if}
                  </div>
                {/each}
              {/if}
            </form>
          </Col>
          <Col id="chat-app_example" md="6" class="d-none d-md-block">
            <div class="center pt-0">
              <div class="whatsapp-chat w-100">
                <div class="header-chat">
                  <div class="head-home">
                    <div class="info-avatar">
                      <img src={channelAdditionalInfo.photo} alt="avatar"/>
                    </div>
                    <p>
                      <span class="whatsapp-name">
                        {channelAdditionalInfo.about || 'John Doe'}
                      </span>
                      <br/>
                      <small>Typically replies within an hour</small>
                    </p>
                  </div>
                </div>
                <div class="start-chat">
                  <div class="whatsapp-chat-body">
                    <div class="whatsapp-chat-message-container">
                      <div class="whatsapp-chat-message">
                        <div class="whatsapp-chat-author">
                          {channelAdditionalInfo.about}
                        </div>
                        <div class="whatsapp-chat-text">
                          {#if newTemplate.header.format === 'TEXT'}
                            <div>
                              <strong>{newTemplate.header.sampleText}</strong>
                            </div>
                          {:else if newTemplate.header.format === 'MEDIA'}
                            <div class="whatsapp-chat-media mb-2">
                              <i class="bx bx-{newTemplate.header.media_type === 'image' ? 'image' : newTemplate.header.media_type === 'video' ? 'play-circle' : 'file'} text-secondary"/>
                            </div>
                          {/if}
                          <div>{@html newTemplate.body.sampleText ?? ''}</div>
                          <div class="font-size-12 mt-2 text-muted">
                            {newTemplate.footer.text}
                          </div>
                        </div>
                        <div class="whatsapp-chat-time">1:40</div>
                      </div>
                    </div>
                    <div class="preview_buttons">
                      {#each newTemplate.buttons as button}
                        <div class="preview_button">
                          {#if button.type !== 'QUICK_REPLY'}
                            <i class="mdi mdi-{button.type === 'URL' ? 'open-in-new' : 'phone' } font-size-16 me-1"/>
                          {/if}
                          {#if button.type === 'OTP'}
                            <i class="bx bx-copy-alt font-size-16 me-1"/>
                          {/if}
                          {button.text}
                        </div>
                      {/each}
                    </div>
                    {#if newTemplate?.carousel}
                      <Swiper slidesPerView={1} spaceBetween={10} {navigation} modules={[Navigation]} class="position-relative">
                        {#each newTemplate.carousel.cards as card}
                          <SwiperSlide>
                            <div class="whatsapp-chat-message-container">
                              <div class="whatsapp-chat-carousel-card">
                                <div class="whatsapp-chat-text">
                                  {#if newTemplate.carousel.header.format === 'MEDIA'}
                                    <div class="whatsapp-chat-media mb-2">
                                      <i class="bx bx-{newTemplate.carousel.header.media_type === 'image' ? 'image' : newTemplate.carousel.header.media_type === 'video' ? 'play-circle' : ''} text-secondary"/>
                                    </div>
                                  {/if}
                                  <div>{@html card.body?.sampleText ?? ''}</div>
                                </div>
                              </div>
                            </div>
                            <div class="preview_buttons flex-column">
                              {#each card.buttons as button, j}
                                <div class="preview_button w-100">
                                  {#if newTemplate.carousel.buttons[j].type !== 'QUICK_REPLY'}
                                    <i class="mdi mdi-{newTemplate.carousel.buttons[j].type === 'URL' ? 'open-in-new' : 'phone'} font-size-16 me-1"/>
                                  {/if}
                                  {button.text}
                                </div>
                              {/each}
                            </div>
                          </SwiperSlide>
                        {/each}
                        <div slot="container-end" class="mt-2 whatsapp-chat-carousel-button">
                          <button type="button" class="swiper-button-prev">
                            <i class="mdi mdi-chevron-left"/>
                          </button>
                          <button type="button" class="swiper-button-next">
                            <i class="mdi mdi-chevron-right"/>
                          </button>
                        </div>
                      </Swiper>
                    {/if}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      {:else}
        <Spinner/>
      {/if}
      <div class="mt-3">
        <Button color="success" class="py-2 px-4" on:click={save}>
          <span class="font-size-14">{$_('save')}</span>
        </Button>
        <Button outline color="danger" class="py-2 px-4" on:click={onClose}>
          <span class="font-size-14">{$_('cancel')}</span>
        </Button>
      </div>
    </ModalBody>
  </Modal>
{/if}

<!-- <div class="add-event-modal">
    <Modal
            isOpen={show}
            fade={true}
            toggle={onClose}
            size="lg"
            class="templates-example"
            scrollable
            centered
    >

        <div class="modal-header">
            <h5 class="modal-title mt-0">
                {$_("waba.template.add")}
                {#if (newTemplate.name)}&nbsp;"{newTemplate.name}"{/if}
            </h5>
            <button on:click={onClose} type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body ">
            {#if ready}
                <Row>
                    <Col layout="column" md="6" xs="12" sm="12">
                        <div>
                            <Card>
                                <CardHeader>
                                    <span class="pr-1">{$_('waba.template.header')}</span>
                                    <span class="badge badge-pill badge-soft-secondary font-size-12">{$_('waba.template.inputs.not_required')}</span>
                                </CardHeader>
                                <CardBody>
                                    <form name="examples" layout-padding layout="column" flex="50">
                                        <div class="" translate="WABA.TEMPLATE.NEW.EXAMPLES.HINT">
                                        </div>

                                        {#if (newTemplate.header)}
                                            {#if (newTemplate.header.format === 'TEXT')}
                                                <div>
                                                    <strong translate="WABA.TEMPLATE.NEW.HEADER"></strong>
                                                    <div>
                                                        {newTemplate.header.text}
                                                    </div>

                                                    {#each newTemplate.header.examples as example, index}
                                                        <input placeholder="{example.name}"
                                                               class="form-control form-control-md"
                                                               on:change={() => {onChange('header')}}
                                                               name="header_example_{index}"
                                                               bind:value="{example.value}" type="text" required/>
                                                    {/each}

                                                </div>
                                            {/if}
                                            {#if (newTemplate.header.format === 'MEDIA')}
                                                <div>
                                                    <strong translate="WABA.TEMPLATE.NEW.HEADER"></strong>
                                                    {#each newTemplate.header.examples as example, index}
                                                        <input placeholder="{example.name}"
                                                               class="form-control form-control-md"
                                                               on:change={() => {onChange('header')}}
                                                               name="header_example_{index}"
                                                               bind:value="{example.value}" type="text" required/>
                                                    {/each}
                                                </div>
                                            {/if}
                                        {/if}
                                        {#if (newTemplate.body?.text)}
                                            <div>
                                                <strong translate="WABA.TEMPLATE.NEW.BODY"></strong>
                                                <div>
                                                    {newTemplate.body?.text}
                                                </div>
                                                {#each newTemplate.body.examples as example, index}
                                                    <input placeholder="{example.name}"
                                                           class="form-control form-control-md"
                                                           on:change={() => { console.log('body!'); onChange('body')}}
                                                           name="body_example_{index}"
                                                           bind:value="{example.value}" type="text" required/>
                                                {/each}

                                            </div>
                                        {/if}
                                        {#if (newTemplate.footer?.text)}
                                            <div>
                                                <strong translate="WABA.TEMPLATE.NEW.FOOTER"></strong>
                                                <div>
                                                    {newTemplate.footer?.text}
                                                </div>
                                            </div>
                                        {/if}

                                        {#if (newTemplate.buttons?.length)}

                                            <div>
                                                <strong translate="WABA.TEMPLATE.NEW.BUTTONS"></strong>
                                                {#each newTemplate.buttons as btn, index}
                                                    <div>
                                                        <div>{index + 1}. {btn.text}</div>
                                                        {#if btn.examples}
                                                            <input placeholder="{btn.examples[0].name}"
                                                                   class="form-control form-control-md"
                                                                   on:change={() => {onChange('buttons')}}
                                                                   bind:value="{btn.examples[0].value}" type="text"
                                                                   required/>
                                                        {/if}

                                                    </div>
                                                {/each}
                                            </div>

                                        {/if}

                                    </form>

                                </CardBody>
                            </Card>


                        </div>
                    </Col>

                    <Col id="chat-app_example" md="6" class="d-none d-md-block">
                        <div class="center" style="padding-top: 0;">
                            <div class="whatsapp-chat">
                                <div class="header-chat">
                                    <div class="head-home">
                                        <div class="info-avatar"><img
                                                src="{channelAdditionalInfo.photo}"/></div>
                                        <p>
                                            <span class="whatsapp-name">{channelAdditionalInfo.about || 'John Doe'}</span>
                                            <br/>
                                            <small>Typically replies within an hour</small>
                                        </p>
                                    </div>
                                </div>
                                <div class="start-chat">
                                    <div class=" whatsapp-chat-body">
                                        <div class="whatsapp-chat-message-container">
                                            <div class="whatsapp-chat-message">
                                                <div class="whatsapp-chat-author">
                                                    {channelAdditionalInfo.about}
                                                </div>
                                                <div class="whatsapp-chat-text">
                                                    {#if newTemplate.header.format === 'TEXT'}
                                                        <div>
                                                            <strong>{newTemplate.header.sampleText}</strong>
                                                        </div>
                                                    {:else if newTemplate.header.format === 'MEDIA'}
                                                        <div class="whatsapp-chat-media mb-2">
                                                            <i class="bx bx-{newTemplate.header.media_type === 'image' ? 'image' : newTemplate.header.media_type === 'video' ? 'play-circle' : 'file'} text-secondary"></i>
                                                        </div>
                                                    {/if}

                                                    <div>{@html newTemplate.body.sampleText}</div>

                                                    <div class="font-size-12 mt-2 text-muted">
                                                        {newTemplate.footer.text}
                                                    </div>
                                                </div>
                                                <div class="whatsapp-chat-time">1:41</div>
                                            </div>
                                        </div>
                                        <div class="preview_buttons">
                                            {#each newTemplate.buttons as button, i}
                                                <div class="preview_button">
                                                    {#if (button.type !== 'QUICK_REPLY')}
                                                        <i class="mdi mdi-{button.type === 'URL' ? 'open-in-new' : 'phone' } font-size-16 me-1"></i>
                                                    {/if}
                                                    {button.text}
                                                </div>
                                            {/each}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            {:else}
                <Spinner></Spinner>
            {/if}

        </div>
        <div class="modal-footer  p-3">
            <button type="button" on:click={onClose}
                    class="btn btn-secondary btn-lg" data-dismiss="modal">
                {$_('close')}
            </button>
            <button type="button" class="btn btn-success btn-lg"
                    on:click={save}>
                {$_('save')}
            </button>
        </div>

    </Modal>
</div> -->
