<script>
  import {Row, Col, Card, CardBody, Button, FormGroup, Label, CardHeader} from "sveltestrap";
  import {_} from "svelte-i18n";
  import {postRequest} from "../../../helpers/utils";
  import TemplateBody from "./TemplateBody.svelte";
  import ExamplesModal from "../../../Components/Modals/AddTemplateExamples.svelte";
  import {Navigation} from 'swiper';
  import {Swiper, SwiperSlide} from 'swiper/svelte';
  import {addToast} from "../../../helpers/toast";
  import {navigate} from "svelte-routing";

  export let newTemplate;
  export let buttonsTypes;
  export let channel;
  export let channelAdditionalInfo;
  export let loading = false;
  export let modal_lg = false;

  let carousel = {
    header: {format: "MEDIA", media_type: "image"},
    buttons: [{actionType: 'reply', type: 'QUICK_REPLY'}],
    cards: [
        {header: {examples: [{name: 'media_url', value: ''}]}, body: {text: ""}, buttons: [{text: ""}]}
    ]
  };
  let variableRegexp = /\{\{\d+\}\}/gm;
  let openExamples = false;
  let navigation ={
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  };

  let swiperState = false;
  $: if (carousel.cards.length) {
    swiperState = false;
    setTimeout(() => {
      swiperState = true;
    }, 1);
  }

  function addButton() {
    carousel.buttons = [...carousel.buttons, {actionType: 'reply', type: 'QUICK_REPLY'}];
    carousel.cards = carousel.cards.map(item => {
      let button = {text: ''};
      item.buttons = [...item.buttons, button];
      return item;
    });
  }

  function reverseButton(type, i) {
    carousel.buttons[i] = {actionType: type.actionType, type: type.code};
    carousel.cards = carousel.cards.map(item => {
      let button = {text: ''};
      if (type.code === 'URL') button.url = "";
      if (type.code === 'PHONE_NUMBER') button.phone_number = "";
      item.buttons[i] = button;
      return item;
    });
  }

  function deleteButton(i) {
    carousel.buttons = carousel.buttons.filter((item, index) => index !== i);
    carousel.cards = carousel.cards.map(item => {
      item.buttons = item.buttons.filter((item, index) => index !== i);
      return item;
    });
  }

  function addVariable(model, field = 'text', max = 0, i = -1, j = -1) {
    const old_value = carousel.cards[i][model][j][field];
    let new_value, variables = old_value.match(variableRegexp);
    if (max && variables?.length >= max) {
      return;
    } else if (variables && variables.length) {
        new_value = old_value + `{{${variables.length + 1}}}`;
    } else {
        new_value = old_value ? old_value + `{{1}}` : `{{1}}`;
    }
    carousel.cards[i][model][j][field] = new_value;
  }

  function addCard() {
    let buttons = carousel.buttons.map(btn => {
      let button = {text: ''};
      if (btn.type === 'URL') button.url = "";
      if (btn.type === 'PHONE_NUMBER') button.phone_number = "";
      return button;
    });
    carousel.cards = [...carousel.cards, {
      header: {examples: [{name: 'media_url', value: ''}]},
      body: {text: ""},
      buttons
    }];
  }

  function deleteCard(i) {
    carousel.cards = carousel.cards.filter((item, index) => index !== i);
  }

  function callbackExamplesModal() {
    openExamples = false;
  }

  function openExamplesModal() {
    openExamples = true;
  }

  async function submitNewTemplate() {
    if (loading) return;
    let stopSubmit = false;

    if (stopSubmit) {
      return;
    }

    loading = true;

    let requestParameters = {
      token: channel.token,
      name: newTemplate.name,
      category: newTemplate.category,
      language: newTemplate.language.code,
      allow_category_change: !!newTemplate.allow_category_change,
      components: []
    };

    let tmp = {
      type: "BODY",
      text: newTemplate.body.text
    };
    if (newTemplate.body.examples && newTemplate.body.examples.length) {
      tmp.example = {
        body_text: [newTemplate.body.examples.map((item) => {
          return (item.value || "")
        })]
      }
    }
    requestParameters.components.push(tmp);

    tmp = {
        type: "CAROUSEL",
        cards: []
    };
    carousel.cards.map(item => {
      let body = {
        type: "BODY",
        text: item.body.text,
      }
      if (item.body.examples && item.body.examples.length) {
        body.example = {
            body_text: [item.body.examples.map((item) => (item.value || ""))]
        }
      }

      let buttons = {
        type: "BUTTONS",
        buttons: []
      }
      buttons.buttons = carousel.buttons.map((btn, i) => {
        let tmp2 = {
            type: btn.type,
            text: item.buttons[i].text
        };
        if (item.buttons[i].url) {
            tmp2.url = item.buttons[i].url;
        }
        if (item.buttons[i].phone_number) {
            tmp2.phone_number = item.buttons[i].phone_number;
        }
        if (item.buttons[i].examples && item.buttons[i].examples.length) {
            tmp2.example = item.buttons[i].examples.map((item) => (item.value || ""));
            if (!tmp2.example.length) delete tmp2.example;
        }
        return tmp2;
      })

      tmp.cards.push({
        components: [
          {
            type: "HEADER",
            format: carousel.header.media_type?.toUpperCase(),
            example: {
              header_handle: [item.header.examples[0].value]
            }
          },
          {...body},
          {...buttons}
        ]
      })
    });
    requestParameters.components.push(tmp)

    try {
        let result = await postRequest(`${channel.apiUrl}addTemplate`, requestParameters);
        loading = false;
        if (!result || result.error || result.message) {
           await addToast({
                title: $_("error"),
                message: result.error || result.message || $_("something_went_wrong"),
                dismissible: true,
                timeout: 4_000,
            });
        } else {
            modal_lg = false;
            await addToast({title: $_("saved")});
            return setTimeout(() => {
                navigate(`/channel/${channel.id}`)
            }, 50);
        }
    } catch (error) {
        loading = false;
        await addToast({
            title: $_("error"),
            message: error || $_("something_went_wrong"),
            dismissible: true,
            timeout: 4_000,
        });
    }
  }
</script>

<Row>
  <Col layout="column" xl="8" xs="12" sm="12">
    <form id="template_content" on:submit|preventDefault={submitNewTemplate}>
        <Card>
            <CardHeader>
                <span class="pr-1">{$_('waba.template.body')}</span>
            </CardHeader>
            <CardBody>
                <TemplateBody bind:template={newTemplate} required={true}/>
            </CardBody>
        </Card>
        <Card>
            <CardHeader>
                <span class="pr-1">{$_('waba.template.carousel')}</span>
            </CardHeader>
            <CardBody>
                <Label>{$_('waba.template.header')}</Label>
                <FormGroup>
                    <div class="form-check form-check-inline font-size-16">
                        <input bind:group={carousel.header.media_type} type="radio" value="image"
                            id="carouselHeaderMediaImage" name="carouselHeaderMediaImage" class="form-check-input" defaultChecked/>
                        <Label class="form-check-label font-size-13" for="carouselHeaderMediaImage" htmlFor="carouselHeaderMediaImage">
                            <i class="fab mdi mdi-image me-1 font-size-20 align-top text-success"/> {$_('waba.template.inputs.image')}
                        </Label>
                    </div>
                    <div class="form-check form-check-inline font-size-16">
                        <input bind:group={carousel.header.media_type} type="radio" value="video"
                            id="carouselHeaderMediaVideo" name="carouselHeaderMediaVideo" class="form-check-input"/>
                        <Label class="form-check-label font-size-13" for="carouselHeaderMediaVideo" htmlFor="carouselHeaderMediaVideo">
                            <i class="far mdi mdi-play-circle-outline me-1 font-size-20 align-top text-danger"/> {$_('waba.template.inputs.video')}
                        </Label>
                    </div>
                </FormGroup>
                <Label>{$_('waba.template.buttons')}</Label>
                <div>
                  {#each carousel.buttons as button, i}
                    <div class="d-flex gap-1 flex-wrap {carousel.buttons.length-1 !== i ? "mb-3" : ""}">
                      {#each buttonsTypes as type}
                        <Button type="button" color="success" class="d-inline-flex align-items-center gap-1"
                          on:click={() => {if(type.actionType !== button.actionType) reverseButton(type, i)}} active={type.actionType === button.actionType}>
                          {$_('waba.template.buttons_types.' + type.code.toLowerCase())}
                        </Button>
                      {/each}
                      {#if i !== 0}
                        <Button type="button" color="danger"class="d-inline-flex" on:click={() => deleteButton(i)}>
                            <i class="bx bx-trash font-size-16"/>
                        </Button>
                      {/if}
                    </div>
                  {/each}
                  {#if carousel.buttons.length < 2}
                    <Button type="button" color="success" outline class="d-inline-flex align-items-center gap-1 mt-3" on:click={addButton}>
                      <i class="bx bx-plus font-size-16 d-none d-sm-inline-block"/> {$_('waba.template.select_type.add_btn')}
                    </Button>
                  {/if}
                </div>
            </CardBody>
        </Card>
        {#each carousel.cards as card, i}
            <Card>
                <CardHeader class="d-flex align-items-center justify-content-between">
                    <span class="pr-1">{$_('waba.template.card')} {i + 1}</span>
                    {#if i !== 0}
                      <button type="button" class="border-0 p-0 bg-transparent d-flex" on:click={() => deleteCard(i)}>
                        <i class="bx bx-trash font-size-18 text-danger"/>
                      </button>
                    {/if}
                </CardHeader>
                <CardBody>
                    <Label>{$_('waba.template.body')}</Label>
                    <FormGroup>
                        <TemplateBody bind:template={card} required={true}/>
                    </FormGroup>
                    {#if carousel.buttons.length}
                      <Label>{$_('waba.template.buttons')}</Label>
                      <div>
                        {#each card.buttons as button, j}
                          <div class="border rounded p-2 {card.buttons.length-1 !== j ? "mb-2" : ""}">
                              <div class="d-flex">
                                  <div class="avatar-xs align-self-center me-2">
                                      <div class="avatar-title rounded bg-transparent text-primary font-size-20">
                                          <i class="mdi mdi-{carousel.buttons[j].type === 'URL' ? 'open-in-new' : carousel.buttons[j].type === 'PHONE_NUMBER' ? 'phone' : 'reply-circle'}"/>
                                      </div>
                                  </div>
                                  <div class="overflow-hidden me-auto">
                                      <h5 class="font-size-16 text-truncate mb-2">{$_('waba.template.buttons_types.' + carousel.buttons[j].type.toLowerCase())}</h5>
                                      <div class="d-flex">
                                          <div class="overflow-hidden me-2">
                                              <input type="text" class="form-control form-control-sm" bind:value={button.text} placeholder={$_('waba.template.inputs.text_button')}/>
                                          </div>
                                          {#if carousel.buttons[j].type === 'URL'}
                                              <div class="overflow-hidden me-2">
                                                  <input type="url" bind:value={button.url} class="form-control" placeholder={$_('waba.template.inputs.url_button')}/>
                                                  <Button type="button" on:click={() => addVariable('buttons', 'url', 1, i, j)}
                                                      class="btn btn-success btn-sm mt-2">
                                                      <i class="bx bx-plus font-size-12"/> {$_('waba.template.add_variable')}
                                                  </Button>
                                              </div>
                                          {/if}
                                          {#if carousel.buttons[j].type === 'PHONE_NUMBER'}
                                              <div class="overflow-hidden me-2">
                                                  <input type="tel" bind:value={button.phone_number} class="form-control" placeholder={$_('waba.template.inputs.phone_button')}/>
                                              </div>
                                          {/if}
                                      </div>
                                  </div>
                              </div>
                          </div>
                        {/each}
                      </div>
                    {/if}
                </CardBody>
            </Card>
        {/each}
        {#if carousel.cards.length < 10}
            <div class="text-end">
              <Button type="button" color="success" class="d-inline-flex align-items-center" on:click={addCard}>
                <i class="bx bx-plus font-size-16"/>
              </Button>
            </div>
        {/if}
    </form>
  </Col>
  <Col id="chat-app_example" xl="4" class="d-none d-xl-block">
      <div class="center" style="padding-top: 0;">
          <div class="whatsapp-chat">
              <div class="header-chat">
                  <div class="head-home">
                      <div class="info-avatar"><img src={channelAdditionalInfo.photo} alt="avatar"/></div>
                      <p>
                          <span class="whatsapp-name">{channelAdditionalInfo.about || 'John Doe'}</span>
                          <br/>
                          <small>Typically replies within an hour</small>
                      </p>
                  </div>
              </div>
              <div class="start-chat">
                  <div class=" whatsapp-chat-body">
                      <div class="whatsapp-chat-message-container">
                          <div class="whatsapp-chat-message">
                              <div class="whatsapp-chat-author">
                                  {channel?.waba_info?.waba_account?.name || 'Author'}
                              </div>
                              <div class="whatsapp-chat-text">
                                  <div>{@html newTemplate.formatedHtml ?? ''}</div>
                              </div>
                              <div class="whatsapp-chat-time">1:40</div>
                          </div>
                      </div>
                      {#if swiperState}
                        <Swiper slidesPerView={1} spaceBetween={10} {navigation} modules={[Navigation]} class="position-relative">
                          {#each carousel.cards as card}
                            <SwiperSlide>
                              <div class="whatsapp-chat-message-container">
                                <div class="whatsapp-chat-carousel-card">
                                  <div class="whatsapp-chat-text">
                                    {#if carousel.header.format === 'MEDIA'}
                                      <div class="whatsapp-chat-media mb-2">
                                        <i class="bx bx-{carousel.header.media_type === 'image' ? 'image' : carousel.header.media_type === 'video' ? 'play-circle' : ''} text-secondary"/>
                                      </div>
                                    {/if}
                                    <div>{@html card.formatedHtml ?? ''}</div>
                                  </div>
                                </div>
                              </div>
                              <div class="preview_buttons flex-column">
                                {#each card.buttons as button, j}
                                  <div class="preview_button w-100">
                                    {#if carousel.buttons[j].type !== 'QUICK_REPLY'}
                                      <i class="mdi mdi-{carousel.buttons[j].type === 'URL' ? 'open-in-new' : 'phone'} font-size-16 me-1"/>
                                    {/if}
                                    {button.text}
                                  </div>
                                {/each}
                              </div>
                            </SwiperSlide>
                          {/each}
                          <div slot="container-end" class="mt-2 whatsapp-chat-carousel-button">
                            <button type="button" class="swiper-button-prev">
                              <i class="mdi mdi-chevron-left"/>
                            </button>
                            <button type="button" class="swiper-button-next">
                              <i class="mdi mdi-chevron-right"/>
                            </button>
                          </div>
                        </Swiper>
                      {:else}
                        <Swiper slidesPerView={1} spaceBetween={10} {navigation} modules={[Navigation]} class="position-relative">
                          {#each carousel.cards as card}
                            <SwiperSlide>
                              <div class="whatsapp-chat-message-container">
                                <div class="whatsapp-chat-carousel-card">
                                  <div class="whatsapp-chat-text">
                                    {#if carousel.header.format === 'MEDIA'}
                                      <div class="whatsapp-chat-media mb-2">
                                        <i class="bx bx-{carousel.header.media_type === 'image' ? 'image' : carousel.header.media_type === 'video' ? 'play-circle' : ''} text-secondary"/>
                                      </div>
                                    {/if}
                                    <div>{@html card.formatedHtml ?? ''}</div>
                                  </div>
                                </div>
                              </div>
                              <div class="preview_buttons flex-column">
                                {#each card.buttons as button, j}
                                  <div class="preview_button w-100">
                                    {#if carousel.buttons[j].type !== 'QUICK_REPLY'}
                                      <i class="mdi mdi-{carousel.buttons[j].type === 'URL' ? 'open-in-new' : 'phone'} font-size-16 me-1"/>
                                    {/if}
                                    {button.text}
                                  </div>
                                {/each}
                              </div>
                            </SwiperSlide>
                          {/each}
                          <div slot="container-end" class="mt-2 whatsapp-chat-carousel-button">
                            <button type="button" class="swiper-button-prev">
                              <i class="mdi mdi-chevron-left"/>
                            </button>
                            <button type="button" class="swiper-button-next">
                              <i class="mdi mdi-chevron-right"/>
                            </button>
                          </div>
                        </Swiper>
                      {/if}
                  </div>
              </div>
          </div>
      </div>
      <div class="justify-content-center d-flex">
          <button on:click={openExamplesModal} class="btn btn-success btn-sm mt-4">
              <span>{$_('waba.template.add_example')}</span>
          </button>
      </div>
  </Col>
</Row>

<ExamplesModal show={openExamples} template={{...newTemplate, carousel}} {channelAdditionalInfo} onClose={callbackExamplesModal}/>