<script>
  import {Button} from "sveltestrap";
  import TextAreaAutosize from "../../../Components/TextAreaAutosize.svelte";
  import {_} from "svelte-i18n";

  export let template;
  export let required = false;

  let variableRegexp = /\{\{\d+\}\}/gm;
  let thisTextArea;

  function addVariable(model, field = 'text', max = 0, index = -1) {
    if (!template[model]) return;
    const old_value = index >= 0 ? template[model][index][field] : template[model][field];
    let new_value, variables = old_value.match(variableRegexp);
    if (variables && max && variables.length > max) {
        new_value = old_value.replace(/\{\{\d+\}\}/, (match, offset) => (offset + 1 >= max) ? "" : `{{${offset + 1}}}`);
    } else if (variables && variables.length) {
        new_value = old_value + `{{${variables.length + 1}}}`
    } else {
        new_value = old_value ? old_value + `{{1}}` : `{{1}}`;
    }

    if (index >= 0) {
      template[model][index][field] = new_value
    } else {
      template[model][field] = new_value;
    }
  }

  function addStyle(style) {
    const start = thisTextArea.selectionStart;
    const end = thisTextArea.selectionEnd;
    let selectedText = thisTextArea.value.substring(start, end);
    selectedText = selectedText ? selectedText : 'Text';

    switch (style) {
        case "bold": {
            template.body.text = `${thisTextArea.value.substring(0, start)}**${selectedText}**${thisTextArea.value.substring(end)}`
            break
        }
        case "italic": {
            template.body.text = `${thisTextArea.value.substring(0, start)}_${selectedText}_${thisTextArea.value.substring(end)}`
            break
        }
        case "strike": {
            template.body.text = `${thisTextArea.value.substring(0, start)}~${selectedText}~${thisTextArea.value.substring(end)}`
            break
        }
    }
  }

  function formatBody() {
    if (!template.body) return;
    if (!template.body.text) {
      template.formatedHtml = "";
      return;
    }
    let formatedBody = template.body.text.replace(/\*\*(.*?)\*\*/g, function (match, contents) {
        return `<strong>${contents}</strong>`;
    });
    formatedBody = formatedBody.replace(/_(.*?)_/g, function (match, contents) {
        return `<em>${contents}</em>`;
    });
    formatedBody = formatedBody.replace(/\*(.*?)\*/g, function (match, contents) {
        return `<em>${contents}</em>`;
    });
    formatedBody = formatedBody.replace(/~(.*?)~/g, function (match, contents) {
        return `<del>${contents}</del>`;
    });
    /* formatedBody = formatedBody.replace(/\{\{\d\}\}/gm, function (match, offset, contents, input_string) {
      match = match.replace(/\{\{/g, '\\{\\{')
      match = match.replace(/\}\}/g, '\\}\\}')
      return match;
    });*/
    formatedBody = formatedBody.replace(/(?:\r\n|\r|\n)/gm, '<br>');
    template.formatedHtml = formatedBody;
  }

</script>

<p>{$_('waba.template.body_hint')}</p>
<TextAreaAutosize pattern={`/^[^\{].*[^\}]$/gm`} minRows={3} bind:value={template.body.text} {required} bind:thisTextArea change={formatBody}/>
<div class="mt-2">
  <span class="btn-group btn-group-sm" role="group" aria-label="Style text">
    <Button type="button" color="secondary" on:click={() => addStyle('bold')}><strong>bold</strong></Button>
    <Button type="button" color="secondary" on:click={() => addStyle('strike')}><del>strike</del></Button>
    <Button type="button" color="secondary" on:click={() => addStyle('italic')}><em>italic</em></Button>
  </span>
  <Button type="button" on:click={() =>addVariable('body')} color="success" size="sm">
      <i class="bx bx-plus font-size-12 align-middle"/> {$_('waba.template.add_variable')}
  </Button>
</div>
