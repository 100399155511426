<script>
  import {Spinner} from "sveltestrap";
  import {chosenChannel, user} from '../../../../helpers/store';
  import {_} from "svelte-i18n";
  import {createEventDispatcher, onDestroy} from 'svelte';
  import OperatorFilter from "./elements/OperatorFilter.svelte";

  export let activeOperators;
  export let activeOperator;
  export let disabled;
  export let operatorIdForFilter;
  export let unreadMessagesFilter;
  export let activeTab;

  const dispatch = createEventDispatcher();
  let expectInputReFocus = false;
  let inputElement;
  let chosenChannelLocal_id;
  let localFilter = '';

  $:{
    if (!disabled && expectInputReFocus) {
      expectInputReFocus = false;
      setTimeout(() => {
        inputElement.focus()
      }, 1)
    }
  }

  const pushChange = () => {
    dispatch('change', {
      localFilter: localFilter ?? ''
    });
    expectInputReFocus = true
  };

  function deleteFilter() {
    localFilter = '';
    pushChange()
  }

  const unsub = chosenChannel.subscribe(async (value) => {
    if (value === null || value === "" || value === undefined) {
      deleteFilter()
    } else {
      if (chosenChannelLocal_id !== value?.id) {
        chosenChannelLocal_id = value?.id
        deleteFilter()
      }
    }
  });

  onDestroy(() => {
    unsub();
  });

  const clickInput = () => {
    if ($user.userHintsHintInbox_5 === false) {
      $user.userHintsHintInbox_5 = true;
    }
  };
</script>

<div class="d-flex mb-2 mb-lg-3 position-relative Search">
  <div class="w-100" class:skeleton-wave={!activeOperator}>
    <form class="card m-0 d-flex flex-row overflow-hidden" on:submit|preventDefault={pushChange}>
      <div class="w-100 position-relative">
        <input bind:this={inputElement} bind:value={localFilter} class="border-0 text-muted form-control" on:change={() => {if(localFilter.length === 0) pushChange()}}
          {disabled} on:click={clickInput} placeholder={$_('chat_inbox.Search.placeholder')} type="text" minlength="4"/>
        {#if localFilter && !disabled}
          <button type="button" class="position-absolute top-0 bx bx-x font-size-20 text-muted border-0 bg-transparent p-0" on:click={deleteFilter}/>
        {/if}
      </div>
      <button type="submit" disabled={!localFilter.length}
        class="p-0 m-0 d-flex align-items-center justify-content-center border-0 btnSearch {!localFilter.length ? 'bg-white' : 'bg-success'}">
        {#if disabled}
          <Spinner size="sm" color={!localFilter.length ? 'muted' : 'white'}/>
        {:else}
          <i class="bx bx-search-alt font-size-20 {!localFilter.length ? 'text-muted' : 'text-white'}"/>
        {/if}
      </button>
    </form>
  </div>
  {#if activeOperator?.type === "shop_administrator" || activeOperator?.type === "operators"}
    <OperatorFilter {activeOperator} {activeOperators} bind:operatorIdForFilter bind:unreadMessagesFilter bind:activeTab />
  {/if}
</div>

<style>
  .Search {
    z-index: 1;
  }

  .Search input {
    padding-right: 40px;
  }

  .Search .btnSearch {
    width: 46px;
    height: 40px;
  }

  .Search .bx-x {
    right: 10px;
    height: 40px;
  }

  .Search .skeleton-wave {
    position: relative;
    overflow: hidden;
    background-color: #f1f1f1; /* Skeleton background color */
    z-index: 2; /* Ensures the skeleton is positioned above other elements */
  }

  .Search .skeleton-wave::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.5), transparent); /* Wave gradient colors */
    animation: wave-animation 1.5s infinite linear;
    z-index: 3; /* Ensures the wave animation is positioned above the underlying content */
  }

  /* Add the following CSS to hide the underlying content */
  .Search .skeleton-wave::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f1f1f1; /* Skeleton background color */
    z-index: 1; /* Ensures the overlay is positioned above other elements */
  }

  @keyframes wave-animation {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(180%);
    }
  }

  .Search .form-control:disabled {
    background-color: #fff;
  }
</style>
