<script>
  import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from "sveltestrap";
  import {_} from "svelte-i18n";
  import OperatorFilterItems from "./OperatorFilterItems.svelte";

  export let activeOperator;
  export let activeOperators;
  export let operatorIdForFilter;
  export let unreadMessagesFilter;
  export let activeTab;

  let isOpen = false;

  $: if (activeOperator?.type === "operators") {
    operatorIdForFilter = activeOperator?.profile?.id;
  }
</script>

<div class="d-flex align-items-center ms-2 OperatorFilter">
  <Dropdown {isOpen} toggle={() => isOpen=!isOpen}>
    <DropdownToggle tag="div" class="pointer d-flex" caret>
      <i class="bx bx-filter"/>
    </DropdownToggle>
    <DropdownMenu end>
      <div class="scrollbar">
        <!-- <DropdownItem header>{$_('chat_inbox.Search.read')}</DropdownItem>
        <DropdownItem>{$_('chat_inbox.Search.yes')}</DropdownItem>
        <DropdownItem>{$_('chat_inbox.Search.no')}</DropdownItem>-->
        <button type="button"
          class="dropdown-item text-truncate"
          class:active={unreadMessagesFilter}
          on:click={() => {
            unreadMessagesFilter = !unreadMessagesFilter;
            isOpen = false;
          }}>
          {$_('chat_inbox.Tab.unread')}
        </button>
        {#if activeOperator?.type === "shop_administrator"}
          <DropdownItem divider />
          <DropdownItem header>{$_('chat_inbox.Search.assigned_to')}</DropdownItem>
          <OperatorFilterItems bind:isOpen {activeOperator} {activeOperators} bind:operatorIdForFilter bind:activeTab />
        {/if}
      </div>
    </DropdownMenu>
  </Dropdown>
</div>

<style>
  .bx-filter {
    font-size: 30px
  }

  .OperatorFilter .scrollbar {
    width: 245px;
    max-height: calc(100vh - 450px);
    overflow-y: auto;
  }

  .OperatorFilter .scrollbar::-webkit-scrollbar {
    width: 3px;
    background-color: transparent;
  }

  .OperatorFilter .scrollbar::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .OperatorFilter .scrollbar .dropdown-item {
    color: #495057;
  }

  .OperatorFilter .scrollbar .dropdown-item.active {
    background-color: #f8f9fa !important;
  }
</style>
