<script>
    import {Row, Col, Card, CardBody, Button, Modal, FormGroup, Label, CardHeader, ModalHeader, ModalBody, Spinner} from "sveltestrap";
    import {Datatable, SearchInput} from "svelte-simple-datatables";
    import {_} from "svelte-i18n";
    import {getRequest, postRequest} from "../../helpers/utils";
    import {languages} from "./languages.js";
    import {addToast} from "../../helpers/toast";
    import {navigate} from "svelte-routing";
    import ExamplesModal from "../../Components/Modals/AddTemplateExamples.svelte";
    import TemplateShowModal from "../../Components/Modals/TemplateShowModal.svelte";
    import TemplateAddCarousel from "./Components/TemplateAddCarousel.svelte";
    import TextAreaAutosize from "../../Components/TextAreaAutosize.svelte";

    export let channel;
    export let channelAdditionalInfo;
    let rows;

    let data, newTemplate = {errors: {}},
      modal = false, modal_lg = false, modal_auth = false, header = 'none', auth_btn = {type: 'COPY_CODE'}, buttonType, templateForModal, showTemplateModal = false,
      showExamplesButton = false, openExamples = false, loading, rejected = {show: false}, thisTextArea, buttonTypeCatalog = false;

    let typeTemplate = 'normal';

    const tog_modal = () => {
        modal = !modal;
    };

    const tog_modal_lg = () => {
      modal_lg = !modal_lg;
      if (!modal_lg) {
        resetTemplate();
      }
    };

    const tog_modal_auth = () => {
      modal_auth = !modal_auth;
    };

    const categories = ['MARKETING', 'UTILITY', 'AUTHENTICATION'];

    const buttonsTypes = [
        {actionType: 'reply', max: 3, code: 'QUICK_REPLY'},
        {actionType: 'url', max: 2, code: 'URL'},
        {actionType: 'number', max: 1, code: 'PHONE_NUMBER'}
    ];

    const init = async () => {
        if (channel.apiUrl) {
            let result = await getRequest(`${channel.apiUrl}templates`, {
                token: channel.token,
            });
            if (result && result.templates) {
                data = result.templates;
            }
        }
    };

    $: if (channel) {
        init();
    }

    const resetTemplate = () => {
        // step = 1;
        header = 'none'
        buttonType = false;
        newTemplate.name = "";
        newTemplate.category = null;
        newTemplate.language = null;
        newTemplate.header = {
            format: 'NONE',
            text: "",
            media_type: null
        };
        newTemplate.body = {text: ""};
        newTemplate.footer = {text: ""};
        newTemplate.buttons = [];
        newTemplate.formatedHtml = "";
    };

    resetTemplate();

    function clearTemplate(type) {
        header = 'none';
        buttonType = false;
        newTemplate.header = {
            format: 'NONE',
            text: "",
            media_type: null
        };
        newTemplate.footer = {text: ""};
        newTemplate.buttons = [];
        typeTemplate = type;
    }

    let deleteTplName = null;
    let loadingDeleteTpl = false;
    let notificationDeleteTpl = false;

    const deleteTpl = async (name) => {
        try {
            loadingDeleteTpl = true;
            let result = await postRequest(`${channel.apiUrl}removeTemplate`, {
                token: channel.token, name
            });
            if (!Object.keys(result).length) {
                notificationDeleteTpl = true;
            } else if (result.result === 'success') {
                await init();
                addToast({title: $_('waba.template.deleted')});
                deleteTplName = null;
            } else if (result.error) {
                addToast({title: $_("waba.template.delete_error"), message: result.error + ""});
            }
            loadingDeleteTpl = false;
        } catch (error) {
            addToast({title: $_("waba.template.delete_error"), message: error + ""});
            loadingDeleteTpl = false;
        }
    };

    const changeHeader = () => {
        newTemplate.header = {
            format: 'NONE',
            text: "",
            media_type: null
        };
        if (header === 'none' || header === 'text') {
            newTemplate.header.format = header.toUpperCase();
        } else {
            newTemplate.header.media_type = header;
            newTemplate.header.format = 'MEDIA';
        }
    };

    const addButton = type => {
        buttonType = type.actionType;
        const button = {
            actionType: type.actionType,
            type: type.code,
            text: "",
        }
        if (type.code === 'URL') button.url = "";
        if (type.code === 'PHONE_NUMBER') button.phone_number = "";
        if (type.code === 'CATALOG') {
            button.text = "View catalog";
            header = 'none';
            buttonTypeCatalog = true;
            changeHeader();
        }

        newTemplate.buttons[newTemplate.buttons.length] = button;

        if (newTemplate.buttons.length === type.max) buttonType = 'max';
    };

    const removeButton = i => {
        newTemplate.buttons.splice(i, 1)
        newTemplate.buttons = newTemplate.buttons;
        buttonType = newTemplate.buttons.length > 0 ? newTemplate.buttons[0].actionType : false;
    };

    let variableRegexp = /\{\{\d+\}\}/gm;

    function addVariable(model, field = 'text', max = 0, index = -1) {
        if (!newTemplate[model]) return;
        const old_value = index >= 0 ? newTemplate[model][index][field] : newTemplate[model][field];
        let new_value, variables = old_value.match(variableRegexp);
        if (max && variables?.length >= max) {
          return;
           // new_value = old_value.replace(/\{\{\d\}\}/, (match, offset) => (offset + 1 >= max) ? "" : `{{${offset + 1}}}`);
        } else if (variables && variables.length) {
            new_value = old_value + `{{${variables.length + 1}}}`
        } else {
            new_value = old_value ? old_value + `{{1}}` : `{{1}}`;
        }

        if (index >= 0) {
            newTemplate[model][index][field] = new_value
        } else {
            newTemplate[model][field] = new_value;
        }

        // console.log(newTemplate[model][field]);
    };

    function addBodyStyle(style) {
        const start = thisTextArea.selectionStart;
        const end = thisTextArea.selectionEnd;
        let selectedText = thisTextArea.value.substring(start, end);
        selectedText = selectedText ? selectedText : 'Text';

        switch (style) {
            case "bold": {
                newTemplate.body.text = `${thisTextArea.value.substring(0, start)}**${selectedText}**${thisTextArea.value.substring(end)}`
                break
            }
            case "italic": {
                newTemplate.body.text = `${thisTextArea.value.substring(0, start)}_${selectedText}_${thisTextArea.value.substring(end)}`
                break
            }
            case "strike": {
                newTemplate.body.text = `${thisTextArea.value.substring(0, start)}~${selectedText}~${thisTextArea.value.substring(end)}`
                break
            }
        }
    }

    function formatBody() {
        if (!newTemplate.body) return;
        if (!newTemplate.body.text) {
            newTemplate.formatedHtml = "";
            return;
        }
        let formatedBody = newTemplate.body.text.replace(/\*\*(.*?)\*\*/g, function (match, contents) {
            return `<strong>${contents}</strong>`;
        });
        formatedBody = formatedBody.replace(/_(.*?)_/g, function (match, contents) {
            return `<em>${contents}</em>`;
        });
        formatedBody = formatedBody.replace(/\*(.*?)\*/g, function (match, contents) {
            return `<em>${contents}</em>`;
        });
        formatedBody = formatedBody.replace(/~(.*?)~/g, function (match, contents) {
            return `<del>${contents}</del>`;
        });
       /* formatedBody = formatedBody.replace(/\{\{\d\}\}/gm, function (match, offset, contents, input_string) {
            match = match.replace(/\{\{/g, '\\{\\{')
            match = match.replace(/\}\}/g, '\\}\\}')
            return match;
        });*/
        formatedBody = formatedBody.replace(/(?:\r\n|\r|\n)/gm, '<br>');

        newTemplate.formatedHtml = formatedBody;
    }

    function validateTemplateName(e) {
        // console.log('validateTemplateName')
        newTemplate.errors.name = false;
        if (!newTemplate.name) {
            newTemplate.errors.name = "waba.template.errors.required";
            return;
        }
        let reg = /[^\da-z_]/g;
        if (newTemplate.name.match(reg)) {
            let name_example = newTemplate.name.toLowerCase();
            name_example = name_example.replace(/[\s-]/g, '_');
            name_example = name_example.replace(/[^\da-z_]/g, '');
            newTemplate.errors.name = "waba.template.errors.name.name_format";
            return;
        }
        // console.log('data', data)
        if (data && data.includes(newTemplate.name)) {
            newTemplate.errors.name = "waba.template.errors.name.not_unique";
        }
    }

    function validateFirstPart() {
        // console.log('validateFirstPart')
        newTemplate.errors.category = !newTemplate.category && "waba.template.errors.required" || false;
        newTemplate.errors.language = !newTemplate.language && "waba.template.errors.required" || false;

        if (!newTemplate.errors.name && !newTemplate.errors.category && !newTemplate.errors.language) {
            tog_modal();
            if(newTemplate.category === 'AUTHENTICATION'){
              tog_modal_auth()
            }else{
              tog_modal_lg()
            }
        }
    }

    async function submitNewTemplate() {
      if (loading) return;
      let validateExamples = checkForVariables();
      for (let part of validateExamples) {
        if (!newTemplate[part]?.examples?.length || newTemplate[part]?.examples.filter((a) => {
          return !a?.value
        }).length) {
          addToast({
            title: $_("error"),
            message: $_("waba.template.step2_subtitle"),
            dismissible: true,
            timeout: 5_000,
          });
          return;
        }
      }

      if(!newTemplate?.body?.text) {
        addToast({
          title: $_("error"),
          message: $_("waba.template.fill_body_err"),
          dismissible: true,
          timeout: 5_000,
        });
        return;
      }

       loading = true;

      let requestParameters = {
            token: channel.token,
            name: newTemplate.name,
            category: newTemplate.category,
            language: newTemplate.language.code,
            allow_category_change: !!newTemplate.allow_category_change,
            components: []
        };

        let tmp = {};

        if (newTemplate.header && newTemplate.header.format === 'TEXT') {
            tmp = {
                type: 'HEADER',
                format: 'TEXT',
                text: newTemplate.header.text
            };
            if (newTemplate.header.examples && newTemplate.header.examples.length) {
                tmp.example = {
                    header_text: newTemplate.header.examples.map((item) => {
                        return (item.value || "")
                    })
                }
            }
            requestParameters.components.push(tmp)
        }

        if (newTemplate.header && newTemplate.header.format === 'MEDIA') {
            tmp = {
                type: 'HEADER',
                format: newTemplate.header.media_type?.toUpperCase()
            };
            if (newTemplate.header.examples && newTemplate.header.examples.length) {
                tmp.example = {
                  header_handle: newTemplate.header.examples.map((item) => {
                        return (item.value || "")
                    })
                }
            }

            requestParameters.components.push(tmp)
        }

        tmp = {
            type: "BODY",
            text: newTemplate.body.text
        };
        if (newTemplate.body.examples && newTemplate.body.examples.length) {
            tmp.example = {
                body_text: [newTemplate.body.examples.map((item) => {
                    return (item.value || "")
                })]
            }
        }
        requestParameters.components.push(tmp)

        if (newTemplate.footer.text && newTemplate.footer.text.length) {
            tmp = {
                type: "FOOTER",
                text: newTemplate.footer.text
            };
            requestParameters.components.push(tmp)
        }

        if (newTemplate.buttons.length) {
            tmp = {
                type: "BUTTONS",
                buttons: []
            };
            for (let btn of newTemplate.buttons) {
                let tmp2 = {
                    type: btn.type,
                    text: btn.text
                };
                if (btn.url) {
                    tmp2.url = btn.url
                }
                if (btn.phone_number) {
                    tmp2.phone_number = btn.phone_number
                }
                if (btn.examples && btn.examples.length) {
                    tmp2.example = btn.examples.map((item) => {
                        return (item.value || "")
                    })
                    if (!tmp2.example.length) delete tmp2.example;
                }
                tmp.buttons.push(tmp2);
            }
            requestParameters.components.push(tmp)
        }

        // console.log('requestParameters', requestParameters);

        try {
            let result = await postRequest(`${channel.apiUrl}addTemplate`, requestParameters);
            loading = false;
            if (!result || result.error || result.message) {
               await addToast({
                    title: $_("error"),
                    message: result.error || result.message || $_("something_went_wrong"),
                    dismissible: true,
                    timeout: 4_000,
                });
            } else {
                modal_lg = false;
                await addToast({title: $_("saved")});
                return setTimeout(() => {
                    navigate(`/channel/${channel.id}`)
                }, 50);
            }
        } catch (error) {
            loading = false;
            await addToast({
                title: $_("error"),
                message: error || $_("something_went_wrong"),
                dismissible: true,
                timeout: 4_000,
            });
        }
    }

    function openShowTemplateModal(template) {
        // console.log('openShowTemplateModal', template)
        let pos = {'HEADER' : 1, 'BODY': 2, 'FOOTER': 3, 'BUTTONS': 4};
        if(template?.components) {
            let t = template;
            t.components.sort((a, b) => {
                return pos[a.type] - pos[b.type];
            })
            templateForModal = t;
        }else {
            templateForModal = false;
        }
        showTemplateModal = !showTemplateModal;
    }

    function checkForVariables() {
        let variableRegexp = /\{\{\d+}}/gm;
        let variable = [];
        if (newTemplate.body && newTemplate.body.text && newTemplate.body.text.match(variableRegexp)) {
            variable.push('body');
        } else {
            newTemplate.body.examples = [];
        }
        if (newTemplate.header && (newTemplate.header.format === "MEDIA" || newTemplate.header.text && newTemplate.header.text.match(variableRegexp))) {
            variable.push('header');
        } else {
            newTemplate.header.examples = [];
        }
        if (newTemplate.buttons && newTemplate.buttons.type && newTemplate.buttons.type.code === 'action' && newTemplate.buttons.button.length) {
            let url = newTemplate.buttons.button.filter((item) => {
                return !!item.url
            });
            if (url && url[0] && url[0].url.match(variableRegexp)) {
                variable.push('buttons');
            } else {
                for (let btn of newTemplate.buttons) {
                    btn.examples = [];
                }
            }
        }
        showExamplesButton = variable;
        return variable;
    }

    function openExamplesModal() {
        // console.log('openExamplesModal')
        openExamples = true;
        // modal_lg = false;
    }

    function callbackExamplesModal(value) {
        if (value) {
            if (value.header?.examples.length) {
                newTemplate.header.examples = value.header.examples;
            }
            if (value.body?.examples.length) {
                newTemplate.body.examples = value.body.examples;
            }
            for (let i in value.buttons) {
                let btn = value.buttons[i];
                if (btn?.examples.length) newTemplate.buttons[i].examples = btn.examples.map((item) => {
                    return item.value
                });
            }
        }
        openExamples = false;
        // modal_lg = true;
    }

    function showRejected(template) {
      rejected = {
        show: true,
        name: template.name,
        reason: template.rejected_reason.toLowerCase()
      };
    }

    const settings = {
        labels: {
            search: 'Search...',    // search input placeholer
            filter: 'Filter',       // filter inputs placeholder
            noRows: 'No entries to found',
            info: 'Showing {start} to {end} of {rows} entries',
            previous: 'Previous',
            next: 'Next',
        },
        blocks: {
            searchInput: false,
        },
        sortable: true,
        pagination: true,
        rowsPerPage: 10,
        columnFilter: false,
    };

    const changeAuthBtn = () => {
      newTemplate.header = {
        format: 'NONE',
        text: "",
        media_type: null
      };
      if (header === 'none' || header === 'text') {
        newTemplate.header.format = header.toUpperCase();
      } else {
        newTemplate.header.media_type = header;
        newTemplate.header.format = 'MEDIA';
      }
    };

    async function submitAuthTemplate() {
      if (loading) return;
      loading = true;

      let requestParameters = {
        token: channel.token,
        name: newTemplate.name,
        category: newTemplate.category,
        language: newTemplate.language.code,
        allow_category_change: !!newTemplate.allow_category_change,
        components: []
      };

      let tmp = {
        type: "BODY"
      };
      if (newTemplate.add_security_recommendation) {
        tmp.add_security_recommendation = newTemplate.add_security_recommendation;
      }

      requestParameters.components.push(tmp)

      if (newTemplate.code_expiration_minutes_enable) {
        tmp = {
          type: "FOOTER",
          code_expiration_minutes: newTemplate.code_expiration_minutes || 10
        };
        requestParameters.components.push(tmp)
      }

      if (auth_btn.type === 'ONE_TAP') {
        if (!auth_btn.package_name || !auth_btn.signature_hash) {
          await addToast({
            title: $_("error"),
            message: $_("something_went_wrong"),
            dismissible: true,
            timeout: 5_000,
          });
          return;
        }
        tmp = {
          type: "BUTTONS",
          buttons: [{
            "type": "OTP",
            "otp_type": "ONE_TAP",
            "text": auth_btn.text,
            "autofill_text": auth_btn.autofill_text,
            "package_name": auth_btn.package_name,
            "signature_hash": auth_btn.signature_hash
          }]
        };
        requestParameters.components.push(tmp)
      } else {
        tmp = {
          type: "BUTTONS",
          buttons: [{
            "type": "OTP",
            "otp_type": "COPY_CODE",
            "text": auth_btn.text
          }]
        };
        requestParameters.components.push(tmp)
      }

    //   console.log('requestParameters', requestParameters);
      try {
        let result = await postRequest(`${channel.apiUrl}addTemplate`, requestParameters);
        loading = false;
        if (!result || result.error || result.message) {
          addToast({
            title: $_("error"),
            message: result.error || result.message || $_("something_went_wrong"),
            dismissible: true,
            timeout: 4_000,
          });
        } else {
          modal_lg = false;
          addToast({title: $_("saved")});
          return setTimeout(() => {
            navigate(`/channel/${channel.id}`)
          }, 50);
        }
      } catch (error) {
        loading = false;
        await addToast({
          title: $_("error"),
          message: error || $_("something_went_wrong"),
          dismissible: true,
          timeout: 4_000,
        });
      }
    }

    $: if (newTemplate.body?.text || newTemplate.header?.text || newTemplate.header?.media_type) {
        checkForVariables();
    }
</script>

<!-- <div class="h2 card-title">{$_('waba.templates')}</div> -->
{#if rows}
    <Row class="datatable">
        <Col class="dt-search ps-0">
            <SearchInput/>
        </Col>
        <Col class="text-sm-end">
            <Button on:click={() => {resetTemplate(); modal = true;  typeTemplate = 'normal'}} class="btn btn-success btn-lg">
                <i class="bx bx-plus font-size-16"/> {$_('waba.template.add')}
            </Button>
        </Col>
    </Row>
{/if}

{#if data}
    <Datatable settings={settings} {data} bind:dataRows={rows}>
        <thead>
            <th>{$_("waba.template.name")}</th>
            <th>{$_("waba.template.category")}</th>
            <th>{$_("waba.template.status")}</th>
            <th></th>
        </thead>
        <tbody>
        {#if rows}
            {#each $rows as row}
                <tr>
                    <td class="pointer" on:click={() => openShowTemplateModal(row)}>{row.name}</td>
                    <td>{row.category}</td>
                    <td>
                        <span class={"badge badge-pill badge-soft-" + (row.status === 'approved' ? 'success' : (row.status === 'rejected' ? 'danger' : 'secondary')) + " font-size-14"}>
                            {row.status}
                        </span>
                        {#if row.status == 'rejected' && !['NONE', ''].includes(row.rejected_reason)}
                            <button class="position-relative p-0 avatar-xs rounded-circle btn btn-light" on:click={() => showRejected(row)}>
                                <span class="avatar-title bg-transparent text-reset">
                                    <i class="bx bx-question-mark"/>
                                </span>
                            </button>
                        {/if}
                    </td>
                    <td>
                        <Button on:click={() => deleteTplName = row.name} class="btn btn-danger">
                            <i class="bx bx-trash font-size-16"/>
                        </Button>
                    </td>
                </tr>
            {/each}
        {/if}
        </tbody>
    </Datatable>
    <Modal isOpen={rejected.show} fade={true} size="sm" class="show-rejected" scrollable centered>
        <ModalHeader toggle={() => rejected.show = false}>
            <span>{rejected.name}</span>
        </ModalHeader>
        <ModalBody>
            <div class="container-fluid">
                <div id="chat-app_example">
                    <div class="d-flex justify-content-center">
                        {$_('template_rejected_reason.' + rejected.reason)}
                    </div>
                </div>
            </div>
        </ModalBody>
    </Modal>
    {#if deleteTplName}
    <Modal isOpen={deleteTplName} fade={true} toggle={() => {if (!loadingDeleteTpl) {deleteTplName = null; notificationDeleteTpl = false}}} centered>
        <ModalBody>
        {#if notificationDeleteTpl}
            <h4 class="text-center mb-0 p-3">
                {$_('waba.template.delete_later', {values: {template_name: `"${deleteTplName}"`}})}
            </h4>
        {:else}
            <h4 class="fw-bold mb-3 text-center">
                {$_('waba.template.delete?')}
            </h4>
            <div class="mb-3 text-center">
                {$_("waba.template.delete_text", {values: {template_name: `"${deleteTplName}"`}})}
            </div>
            <div class="text-center">
                <span class="d-inline-block me-3 font-size-14 pointer text-success" on:click={() => {if (!loadingDeleteTpl) deleteTplName = null}}>
                    {$_("no")}
                </span>
                <Button color="danger" class="font-size-16 text-uppercase py-1 px-4"
                    disabled={loadingDeleteTpl} on:click={() => deleteTpl(deleteTplName)}>
                    {#if loadingDeleteTpl}
                        <Spinner size="sm"/>
                    {:else}
                        {$_("yes")}
                    {/if}
                </Button>
            </div>
        {/if}
        </ModalBody>
    </Modal>
    {/if}
{:else}
    <div>
        <div class="my-3">{@html $_('waba.no_templates_text')}</div>
        <Button on:click={() => {resetTemplate(); modal = true;  typeTemplate = 'normal'}} class="btn btn-success btn-lg">
            <i class="bx bx-plus font-size-16"/> {$_('waba.template.add')}
        </Button>
    </div>
{/if}

<Modal isOpen={modal} toggle={tog_modal} size="lg">
    <div class="modal-header">
        <h5 class="modal-title mt-0" id="mySmallModalLabel">
            {$_("waba.template.add")}
        </h5>
        <button on:click={tog_modal} type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form id="step1" data-parsley-validate="" on:submit|preventDefault={validateFirstPart}>
            <FormGroup class="mb-3 position-relative">
                <label class="form-label" for="tpl_name">{$_('waba.template.inputs.name')}:</label>
                <p>{$_('waba.template.name_hint')}</p>
                <input class:is-invalid={newTemplate.errors.name} type="text" bind:value={newTemplate.name}
                    on:change={validateTemplateName} placeholder={$_('waba.template.name')}
                    id="tpl_name" class="form-control" required/>
                {#if newTemplate.errors.name}
                    <div class="invalid-tooltip">
                        {$_(newTemplate.errors.name)}
                    </div>
                {/if}
            </FormGroup>
            <FormGroup class="mb-3 position-relative">
                <label class="form-label" for="tpl_category">{$_('waba.template.inputs.category')}:</label>
                <p>{$_('waba.template.category_hint')}</p>
                <select class:is-invalid={newTemplate.errors.category} type="select" name="select" class="form-select"
                    id="tpl_category" required bind:value={newTemplate.category}>
                    <option value="{null}">{$_('waba.template.inputs.category')}</option>
                    {#each categories as cat}
                        <option value={cat}>{$_('waba.template.categories.' + cat.toLowerCase())}</option>
                    {/each}
                </select>
                <div class="invalid-tooltip">
                    {$_("errors.wrong_value")}
                </div>
            </FormGroup>
            <FormGroup class="mb-3 position-relative">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="allow_category_change" bind:checked={newTemplate.allow_category_change}/>
                    <label class="form-check-label" for="allow_category_change">
                        {$_('waba.template.inputs.allow_category_change')}
                    </label>
                </div>
            </FormGroup>
            <FormGroup class="mb-3 position-relative">
                <label class="form-label" for="tpl_language">{$_('waba.template.inputs.language')}:</label>
                <p>{$_('waba.template.language_hint')}</p>
                <select class:is-invalid={newTemplate.errors.language} type="select" name="select" class="form-select"
                    id="tpl_language" required bind:value={newTemplate.language}>
                    <option value="{null}">{$_('waba.template.inputs.language')}</option>
                    {#each languages as lang}
                        <option value={lang}>{lang.name}</option>
                    {/each}
                </select>
                <div class="invalid-tooltip">
                    {$_("errors.wrong_value")}
                </div>
            </FormGroup>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" on:click={tog_modal} class="btn btn-secondary btn-lg" data-dismiss="modal">
            {$_('close')}
        </button>
        <button type="submit" form="step1" class="btn btn-success btn-lg">
            {$_('next')}
        </button>
    </div>
</Modal>

<Modal isOpen={modal_lg} toggle={tog_modal_lg} size="xl">
    <div class="modal-header">
        <h5 class="modal-title mt-0">
            {$_("waba.template.add")}
            {#if (newTemplate.name)}&nbsp;"{newTemplate.name}"{/if}
        </h5>
        <button on:click={tog_modal_lg} type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        {#if channelAdditionalInfo?.isCloud && (newTemplate.category === 'MARKETING' || newTemplate.category === 'UTILITY')}
            <FormGroup class="d-flex gap-1 flex-wrap">
                <Button type="button" color="success" on:click={() => clearTemplate('normal')} active={typeTemplate === 'normal'}>
                    {$_("waba.template.select_type.normal")}
                </Button>
                <Button  type="button" color="success" on:click={() => clearTemplate('carousel')} active={typeTemplate === 'carousel'}>
                    {$_("waba.template.select_type.carousel")}
                </Button>
            </FormGroup>
        {/if}
        {#if typeTemplate === 'normal'}
            <Row>
                <Col layout="column" xl="8" xs="12" sm="12">
                    <form id="template_content" on:submit|preventDefault={submitNewTemplate}>
                        <Card>
                            <CardHeader>
                                <span class="pr-1">{$_('waba.template.header')}</span>
                                <span class="badge badge-pill badge-soft-secondary font-size-12">{$_('waba.template.inputs.not_required')}</span>
                            </CardHeader>
                            <CardBody>
                                <p>{$_('waba.template.header_hint')}</p>
                                <div class="mt-4">
                                    <div class="form-check form-check-inline font-size-16">
                                        <input bind:group={header} on:change={changeHeader} type="radio" value="none"
                                            id="customRadioInline1" name="customRadioInline1" class="form-check-input" defaultChecked disabled={buttonTypeCatalog}/>
                                        <Label class="form-check-label font-size-13" for="customRadioInline1" htmlFor="customRadioInline1">
                                            <i class="fab mdi mdi-circle-off-outline me-1 font-size-20 align-top text-secondary "/>{" "} {$_('waba.template.header_format.none')}
                                        </Label>
                                    </div>
                                    <div class="form-check form-check-inline font-size-16">
                                        <input bind:group={header} on:change={changeHeader} type="radio" value="text"
                                            id="customRadioInline2" name="customRadioInline1" class="form-check-input" disabled={buttonTypeCatalog}/>
                                        <Label class="form-check-label font-size-13" for="customRadioInline2" htmlFor="customRadioInline2">
                                            <i class="fab mdi mdi-comment-text-outline me-1 font-size-20 align-top text-info "/>{" "} {$_('waba.template.header_format.text')}
                                        </Label>
                                    </div>
                                    <div class="form-check form-check-inline font-size-16">
                                        <input bind:group={header} on:change={changeHeader} type="radio" value="image"
                                            id="customRadioInline3" name="customRadioInline1" class="form-check-input" disabled={buttonTypeCatalog}/>
                                        <Label class="form-check-label font-size-13" for="customRadioInline3" htmlFor="customRadioInline3">
                                            <i class="fab mdi mdi-image me-1 font-size-20 align-top text-success "/>{" "} {$_('waba.template.inputs.image')}
                                        </Label>
                                    </div>
                                    <div class="form-check form-check-inline font-size-16">
                                        <input bind:group={header} on:change={changeHeader} type="radio" value="document"
                                            id="customRadioInline4" name="customRadioInline1" class="form-check-input" disabled={buttonTypeCatalog}/>
                                        <Label class="form-check-label font-size-13" for="customRadioInline4" htmlFor="customRadioInline4">
                                            <i class="fab mdi mdi-file-document me-1 font-size-20 align-top text-primary"/>{" "} {$_('waba.template.inputs.document')}
                                        </Label>
                                    </div>
                                    <div class="form-check form-check-inline font-size-16">
                                        <input bind:group={header} on:change={changeHeader} type="radio" value="video"
                                            id="customRadioInline5" name="customRadioInline1" class="form-check-input" disabled={buttonTypeCatalog}/>
                                        <Label class="form-check-label font-size-13" for="customRadioInline5" htmlFor="customRadioInline5">
                                            <i class="far mdi mdi-play-circle-outline me-1 font-size-20 align-top text-danger"/>{" "} {$_('waba.template.inputs.video')}
                                        </Label>
                                    </div>
                                </div>
                                {#if newTemplate.header.format === 'TEXT'}
                                    <div class="mt-4">
                                        <TextAreaAutosize minRows={3} bind:value={newTemplate.header.text} required={true}/>
                                        <Button type="button" on:click={() => addVariable('header', 'text', 1)} class="btn btn-success btn-sm mt-2">
                                            <i class="bx bx-plus font-size-12"/> {$_('waba.template.add_variable')}
                                        </Button>
                                    </div>
                                {/if}
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader>
                                <span class="pr-1">{$_('waba.template.body')}</span>
                            </CardHeader>
                            <CardBody>
                                <p>{$_('waba.template.body_hint')}</p>
                                <div class="mt-4">
                                    <TextAreaAutosize pattern={`/^[^\{].*[^\}]$/gm`} minRows={3} bind:value={newTemplate.body.text} required={true} bind:thisTextArea change={formatBody}/>
                                    <div class="mt-2 d-flex flex-wrap gap-1">
                                        <span class="btn-group btn-group-sm" role="group" aria-label="Style text">
                                            <Button type="button" color="secondary" on:click={() => addBodyStyle('bold')}><strong>bold</strong></Button>
                                            <Button type="button" color="secondary" on:click={() => addBodyStyle('strike')}><del>strike</del></Button>
                                            <Button type="button" color="secondary" on:click={() => addBodyStyle('italic')}><em>italic</em></Button>
                                        </span>
                                        <Button type="button" on:click={() => addVariable('body')} class="btn btn-success btn-sm">
                                            <i class="bx bx-plus font-size-12"/> {$_('waba.template.add_variable')}
                                        </Button>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader>
                                <span class="pr-1">{$_('waba.template.footer')}</span>
                                <span class="badge badge-pill badge-soft-secondary font-size-12">{$_('waba.template.inputs.not_required')}</span>
                            </CardHeader>
                            <CardBody>
                                <p>{$_('waba.template.footer_hint')}</p>
                                <div class="mt-4">
                                    <input type="text" bind:value={newTemplate.footer.text}  class="form-control" maxlength="60"/>
                                </div>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader>
                                <span class="pr-1">{$_('waba.template.buttons')}</span>
                                <span class="badge badge-pill badge-soft-secondary font-size-12">{$_('waba.template.inputs.not_required')}</span>
                            </CardHeader>
                            <CardBody>
                                <p>{$_('waba.template.buttons_hint')}</p>
                                <div class="mt-2">
                                    {#each newTemplate.buttons as button, i}
                                        <div class="card border shadow-none mb-2">
                                            <div class="text-body">
                                                <div class="p-2">
                                                    <div class="d-flex">
                                                        <div class="avatar-xs align-self-center me-2">
                                                            <div class="avatar-title rounded bg-transparent text-primary font-size-20">
                                                                <i class="mdi mdi-{button.type === 'URL' ? 'open-in-new' : button.type === 'PHONE_NUMBER' ? 'phone' : 'reply-circle'}"/>
                                                            </div>
                                                        </div>
                                                        <div class="overflow-hidden me-auto">
                                                            <h5 class="font-size-16 text-truncate mb-2">{$_('waba.template.buttons_types.' + button.type.toLowerCase())}</h5>
                                                            <div class="d-flex">
                                                                <div class="overflow-hidden me-2">
                                                                    <input type="text" bind:value={button.text}
                                                                        class="form-control form-control-sm"
                                                                        placeholder={$_('waba.template.inputs.text_button')}
                                                                        disabled={button.type === 'CATALOG'}/>
                                                                </div>
                                                                {#if button.type === 'URL'}
                                                                    <div class="overflow-hidden me-2">
                                                                        <input type="url" bind:value={button.url}
                                                                            class="form-control"
                                                                            placeholder={$_('waba.template.inputs.url_button')}/>
                                                                        <Button type="button" on:click={() => addVariable('buttons', 'url', 1, i)}
                                                                            class="btn btn-success btn-sm mt-2">
                                                                            <i class="bx bx-plus font-size-12"/> {$_('waba.template.add_variable')}
                                                                        </Button>
                                                                    </div>
                                                                {/if}
                                                                {#if button.type === 'PHONE_NUMBER'}
                                                                    <div class="overflow-hidden me-2">
                                                                        <input type="tel" bind:value={button.phone_number}
                                                                            class="form-control"
                                                                            placeholder={$_('waba.template.inputs.phone_button')}/>
                                                                    </div>
                                                                {/if}
                                                                <p class="text-muted">
                                                                    <Button type="button" on:click={() => {removeButton(i); buttonTypeCatalog = false}}
                                                                        class="btn btn-danger">
                                                                        <i class="bx bx-trash font-size-16"/>
                                                                    </Button>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    {/each}
                                    <div class="d-flex gap-2 flex-wrap">
                                        {#each buttonsTypes as type}
                                            <Button type="button" on:click={() => addButton(type)} color="success" class="d-inline-flex align-items-center gap-1"
                                                disabled={buttonType && buttonType !== type.actionType}>
                                                <i class="bx bx-plus font-size-16 d-none d-sm-inline-block"/> {$_('add')} {$_('waba.template.buttons_types.' + type.code.toLowerCase())}
                                            </Button>
                                        {/each}
                                        {#if newTemplate.category === 'MARKETING' && channelAdditionalInfo?.isCloud}
                                            <Button type="button" on:click={() => addButton({actionType: 'catalog', max: 1, code: 'CATALOG'})} color="success"
                                                class="d-inline-flex align-items-center gap-1" disabled={buttonType && buttonType !== 'catalog'}>
                                                <i class="bx bx-plus font-size-16 d-none d-sm-inline-block"/> {$_('add')} {$_('waba.template.buttons_types.' + 'CATALOG'.toLowerCase())}
                                            </Button>
                                        {/if}
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </form>
                    <!-- <div layout="row" layout-align="center">
                        <div class="pv-20" ng-if="vm.loading" layout="column" layout-align="start center">
                            <md-progress-circular md-diameter="30" class="md-accent mt-20" md-mode="indeterminate"></md-progress-circular>
                        </div>
                        <div ng-hide="vm.loading">
                            <md-button ng-show="vm.showVariable" class=" hide-gt-sm btn btn__cyan-border is-mini-radius is-wa ph-10 ml-32 mb-15" ng-click="vm.addExample()" ng-disabled="templateForm.$invalid || templateForm.$pristine">
                                <span translate="WABA.TEMPLATE.NEW.ADD_EXAMPLE"></span>
                            </md-button>
                            <md-button class="md-raised md-accent ph-10 pv-5" ng-click="vm.back()">
                                <span translate="BACK"></span>
                            </md-button>
                            <md-button class="md-raised md-accent ph-10 pv-5" ng-click="vm.addTemplate()" ng-disabled="templateForm.$invalid || templateForm.$pristine">
                                <span translate="SAVE"></span>
                            </md-button>
                        </div>
                    </div>-->
                </Col>
                <Col id="chat-app_example" xl="4" class="d-none d-xl-block">
                    <div class="center" style="padding-top: 0;">
                        <div class="whatsapp-chat">
                            <div class="header-chat">
                                <div class="head-home">
                                    <div class="info-avatar"><img src={channelAdditionalInfo.photo} alt="avatar"/></div>
                                    <p>
                                        <span class="whatsapp-name">{channelAdditionalInfo.about || 'John Doe'}</span>
                                        <br/>
                                        <small>Typically replies within an hour</small>
                                    </p>
                                </div>
                            </div>
                            <div class="start-chat">
                                <div class=" whatsapp-chat-body">
                                    <div class="whatsapp-chat-message-container">
                                        <div class="whatsapp-chat-message">
                                            <div class="whatsapp-chat-author">
                                                {channel?.waba_info?.waba_account?.name || 'Author'}
                                            </div>
                                            <div class="whatsapp-chat-text">
                                                {#if newTemplate.header.format === 'TEXT'}
                                                    <div><strong>{newTemplate.header.text}</strong></div>
                                                {/if}
                                                {#if newTemplate.header.format === 'MEDIA'}
                                                    <div class="whatsapp-chat-media mb-2">
                                                        <i class="bx bx-{newTemplate.header.media_type === 'image' ? 'image' : newTemplate.header.media_type === 'video' ? 'play-circle' : 'file'} text-secondary"/>
                                                    </div>
                                                {/if}
                                                <div>{@html newTemplate.formatedHtml}</div>
                                                <div class="font-size-12 mt-2 text-muted">
                                                    {newTemplate.footer.text}
                                                </div>
                                            </div>
                                            <div class="whatsapp-chat-time">1:40</div>
                                        </div>
                                    </div>
                                    <div class="preview_buttons">
                                        {#each newTemplate.buttons as button, i}
                                            <div class="preview_button">
                                                {#if button.type !== 'QUICK_REPLY' && button.type !== 'CATALOG'}
                                                    <i class="mdi mdi-{button.type === 'URL' ? 'open-in-new' : 'phone'} font-size-16 me-1"/>
                                                {/if}
                                                {button.text}
                                            </div>
                                        {/each}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="justify-content-center d-flex">
                        <button on:click={openExamplesModal} class:d-none={!showExamplesButton} class="btn btn-success btn-sm mt-4">
                            <span>{$_('waba.template.add_example')}</span>
                        </button>
                    </div>
                </Col>
            </Row>
        {/if}
        {#if typeTemplate === 'carousel'}
            <TemplateAddCarousel bind:newTemplate {buttonsTypes} {channel} {channelAdditionalInfo} bind:loading bind:modal_lg/>
        {/if}
    </div>
    <div class="modal-footer">
        <button type="button" on:click={tog_modal_lg} class="btn btn-secondary btn-lg" data-dismiss="modal">
            {$_('close')}
        </button>
        <button type="submit" form="template_content" class="btn btn-success btn-lg">
            {#if loading}
                <i class="bx bx-loader bx-spin font-size-16 align-middle me-2"/>
            {/if}
            {$_('next')}
        </button>
    </div>
</Modal>

<Modal isOpen={modal_auth} toggle={tog_modal_auth} size="xl">
    <div class="modal-header">
        <h5 class="modal-title mt-0">
            {$_("waba.template.add")}
            {#if newTemplate.name}&nbsp;"{newTemplate.name}"{/if}
        </h5>
        <button on:click={tog_modal_auth} type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <Row>
            <Col layout="column" md="8" xs="12" sm="12">
                <form id="template_content_auth" on:submit|preventDefault={submitAuthTemplate}>
                    <Card>
                        <CardHeader>
                            <span class="pr-1">{$_('waba.template.body')}</span>
                        </CardHeader>
                        <CardBody>
                            <p>{$_('waba.template.body_hint_auth')}</p>
                            <FormGroup class="mb-3 position-relative">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" id="add_security_recommendation" bind:checked={newTemplate.add_security_recommendation}/>
                                    <label class="form-check-label" for="add_security_recommendation">
                                        {$_('waba.template.inputs.add_security_recommendation')}
                                    </label>
                                </div>
                            </FormGroup>
                            <div class="mt-4">
                                <div class="card border shadow-none mb-2 p-2">
                                    <div>&lt;VERIFICATION_CODE&gt; is your verification code.</div>
                                    {#if newTemplate.add_security_recommendation}
                                        <div> For your security, do not share this code.</div>
                                    {/if}
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardHeader>
                            <span class="pr-1">{$_('waba.template.footer')}</span>
                            <span class="badge badge-pill badge-soft-secondary font-size-12">{$_('waba.template.inputs.not_required')}</span>
                        </CardHeader>
                        <CardBody>
                            <p>{$_('waba.template.footer_hint_auth')}</p>
                            <div class="mt-4">
                                <FormGroup class="mb-3 position-relative">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" id="code_expiration_minutes_enable" bind:checked={newTemplate.code_expiration_minutes_enable}/>
                                        <label class="form-check-label" for="code_expiration_minutes_enable">
                                            {$_('waba.template.inputs.code_expiration_minutes_enable')}
                                        </label>
                                    </div>
                                </FormGroup>
                                <div>
                                    {#if newTemplate.code_expiration_minutes_enable}
                                        <FormGroup class="mb-3 position-relative">
                                            <label class="form-check-label" for="code_expiration_minutes">
                                                {$_('waba.template.inputs.code_expiration_minutes')}
                                            </label>
                                            <input class="form-control w-10 d-inline" type="number" min="1" max="90" id="code_expiration_minutes" bind:value={newTemplate.code_expiration_minutes}/>
                                        </FormGroup>
                                        <div class="card border shadow-none p-2">This code expires in {newTemplate.code_expiration_minutes || '10'} minutes</div>
                                    {/if}
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardHeader>
                            <span class="pr-1">{$_('waba.template.buttons')}</span>
                        </CardHeader>
                        <CardBody>
                            <p>{$_('waba.template.buttons_hint_auth')}</p>
                            <div class="my-2">
                                <div class="form-check form-check-inline font-size-16">
                                    <input bind:group={auth_btn.type} on:change={changeAuthBtn} type="radio" value="COPY_CODE"
                                        id="radio_btn1" name="customRadioInline1" class="form-check-input"/>
                                    <Label class="form-check-label font-size-13" htmlFor="radio_btn1">
                                        <i class="fab bx bx-copy-alt me-1 font-size-20 align-top text-info "/>{" "} {$_('waba.template.auth_btn.copy_code')}
                                    </Label>
                                </div>
                                <div class="form-check form-check-inline font-size-16">
                                    <input bind:group={auth_btn.type} on:change={changeAuthBtn} type="radio" value="ONE_TAP"
                                        id="radio_btn2" name="customRadioInline1" class="form-check-input"/>
                                    <Label class="form-check-label font-size-13" htmlFor="radio_btn2">
                                        <i class="fab mdi mdi-exit-to-app me-1 font-size-20 align-top text-info "/>{" "} {$_('waba.template.auth_btn.one_tap')}
                                    </Label>
                                </div>
                            </div>
                            <div>
                                <div class="card border shadow-none mb-2">
                                    <div class="text-body">
                                        <FormGroup class="m-3" row>
                                            <Label htmlFor="address" md="3" class="col-form-label">
                                                <i class="fab font-size-20 align-top text-info {auth_btn.type === 'ONE_TAP' && 'mdi mdi-android' || 'bx bx-copy-alt'}"/>{" "} {$_('waba.template.inputs.text_button')}
                                            </Label>
                                            <Col md="9">
                                                <input type="text" bind:value={auth_btn.text} maxlength="25"
                                                    class="form-control form-control-sm" placeholder={$_('waba.template.inputs.text_button')}/>
                                            </Col>
                                        </FormGroup>
                                        {#if auth_btn.type === 'ONE_TAP'}
                                            <FormGroup class="m-3" row>
                                                <Label htmlFor="address" md="3" class="col-form-label">
                                                    <i class="fab mdi mdi-android me-1 font-size-20 align-top text-info"/>{" "} {$_('waba.template.inputs.package_name')}
                                                </Label>
                                                <Col md="9">
                                                    <input type="url" bind:value={auth_btn.package_name}
                                                        class="form-control" required placeholder={$_('waba.template.inputs.package_name')}/>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup class="m-3" row>
                                                <Label htmlFor="address" md="3" class="col-form-label">
                                                    <i class="fab mdi mdi-application-cog me-1 font-size-20 align-top text-info"/>{" "} {$_('waba.template.inputs.signature_hash')}
                                                </Label>
                                                <Col md="9">
                                                    <input type="url" bind:value={auth_btn.signature_hash}
                                                        class="form-control" required placeholder={$_('waba.template.inputs.signature_hash')}/>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup class="m-3" row>
                                                <Label htmlFor="address" md="3" class="col-form-label">
                                                    <i class="fab mdi mdi-text me-1 font-size-20 align-top text-info"/>{" "} {$_('waba.template.inputs.autofill_text')}
                                                </Label>
                                                <Col md="9">
                                                    <input type="url" bind:value={auth_btn.autofill_text}
                                                        class="form-control" required placeholder={$_('waba.template.inputs.autofill_text')}/>
                                                </Col>
                                            </FormGroup>
                                        {/if}
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </form>
            </Col>
            <Col id="chat-app_example_auth" md="4" class="d-none d-md-block">
                <div class="center" style="padding-top: 0;">
                    <div class="whatsapp-chat">
                        <div class="header-chat">
                            <div class="head-home">
                                <div class="info-avatar"><img src={channelAdditionalInfo.photo} alt="avatar"/></div>
                                <p>
                                    <span class="whatsapp-name">{channelAdditionalInfo.about || 'John Doe'}</span>
                                    <br/>
                                    <small>Typically replies within an hour</small>
                                </p>
                            </div>
                        </div>
                        <div class="start-chat">
                            <div class=" whatsapp-chat-body">
                                <div class="whatsapp-chat-message-container">
                                    <div class="whatsapp-chat-message">
                                        <div class="whatsapp-chat-author">
                                            {channel?.waba_info?.waba_account?.name || 'Author'}
                                        </div>
                                        <div class="whatsapp-chat-text">
                                            <div>&lt;VERIFICATION_CODE&gt; is your verification code.
                                                {#if newTemplate.add_security_recommendation}
                                                    For your security, do not share this code.
                                                {/if}
                                            </div>
                                            <div class="font-size-12 mt-2 text-muted">
                                                {#if newTemplate.code_expiration_minutes}
                                                    This code expires in &lt;NUM_MINUTES&gt; minutes
                                                {/if}
                                            </div>
                                        </div>
                                        <div class="whatsapp-chat-time">1:45</div>
                                    </div>
                                </div>
                                <div class="preview_buttons">
                                    <div class="preview_button">
                                        <i class="{auth_btn.type === 'ONE_TAP' ? 'mdi mdi-android' : 'bx bx-copy-alt'} font-size-16 me-1"/>
                                        {auth_btn.text || ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    </div>
    <div class="modal-footer">
        <button type="button" on:click={tog_modal_auth} class="btn btn-secondary btn-lg" data-dismiss="modal">
            {$_('close')}
        </button>
        <button type="submit" form="template_content_auth" class="btn btn-success btn-lg">
            {#if loading}
                <i class="bx bx-loader bx-spin font-size-16 align-middle me-2"/>
            {/if}
            {$_('next')}
        </button>
    </div>
</Modal>

<ExamplesModal show={openExamples} template={newTemplate} {channelAdditionalInfo} onClose={callbackExamplesModal}/>

{#if templateForModal}
    <TemplateShowModal template={templateForModal} {channel} show={showTemplateModal} toggle={openShowTemplateModal} {channelAdditionalInfo}/>
{/if}
